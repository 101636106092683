<template>
  <div>
    <course-date-detail-header
      v-if="courseDate"
      :course-date-data="courseDate"
    />
    <div v-else>
      Loading...
    </div>
    <router-view />
  </div>
</template>

<script>
import { FETCH_COURSE_DATE } from '@/modules/course-date/store/action-types'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import CourseDateDetailHeader from './components/CourseDateDetailHeader.vue'

export default {
  components: { CourseDateDetailHeader },
  setup() {
    const { route } = useRouter()
    const courseDate = ref(null)

    const loadCourseDate = async () => {
      store.dispatch(FETCH_COURSE_DATE, route.value.params.slug)
        .then(res => {
          courseDate.value = res.data.data
        })
    }

    loadCourseDate()

    return {
      courseDate,
    }
  },
}
</script>

<style scoped>

</style>
