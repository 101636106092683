<template>
  <b-card>
    <b-table
      class="position-relative"
      :items="roles"
      responsive
      show-empty
      empty-text="No matching records found"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import { ROLES_PERMISSIONS_FETCH } from '@/modules/role-permission/store/action-types'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'RolesPermissionsPage',
  components: {
    BCard,
    BTable,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    roles: null,
  }),
  created() {
    this.loadSettings()
  },
  methods: {
    async loadSettings() {
      // const id = +this.$route.params.id;
      await this.$store.dispatch(ROLES_PERMISSIONS_FETCH)
        .then(response => {
          this.roles = response.data.data
        })
    },
  },
}
</script>
