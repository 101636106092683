import { render, staticRenderFns } from "./OrganizationEmployeeInvite.vue?vue&type=template&id=8e4d4e60&scoped=true&"
import script from "./OrganizationEmployeeInvite.vue?vue&type=script&lang=js&"
export * from "./OrganizationEmployeeInvite.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e4d4e60",
  null
  
)

export default component.exports