import { useToast } from 'vue-toastification/composition'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import {
  FETCH_FACILITIES,
  FETCH_FACILITIES_FILTERS,
  REMOVE_FACILITY,
  UPDATE_FACILITY,
} from '@/modules/facility/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFacilities() {
  const toast = useToast()

  const refFacilityListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'organization', sortable: true },
    // { key: 'details', sortable: true },
    { key: 'status_label', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalFacilities = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  // Filter
  const statusOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deactivated', value: 'deactivated' },
  ]
  const statusFilter = ref(null)
  const organizationsFilter = ref(null)
  const organizationsOptions = ref([])
  const establishmentsFilter = ref(null)
  const establishmentsOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refFacilityListTable.value ? refFacilityListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFacilities.value,
    }
  })

  const refetchData = () => {
    refFacilityListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, establishmentsFilter, organizationsFilter], () => {
    refetchData()
  })

  const confirm = facility => {
    store
      .dispatch(UPDATE_FACILITY, {
        uuid: facility.uuid,
        status: 'confirm',
      })
      .then(() => {
        refetchData()
      })
  }

  const reject = facility => {
    store
      .dispatch(UPDATE_FACILITY, {
        uuid: facility.uuid,
        status: 'reject',
      })
      .then(() => {
        refetchData()
      })
  }

  const deleteFacility = facility => {
    store
      .dispatch(REMOVE_FACILITY, facility.uuid)
      .then(() => {
        refetchData()
      })
  }

  const fetchFacilities = (ctx, callback) => {
    store
      .dispatch(FETCH_FACILITIES, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        organizations: organizationsFilter.value,
        establishments: establishmentsFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalFacilities.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching facility list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchFacilitiesFilters = async () => {
    store.dispatch(FETCH_FACILITIES_FILTERS)
      .then(response => {
        organizationsOptions.value = response.data.organizations
        establishmentsOptions.value = response.data.establishments
      })
  }

  fetchFacilitiesFilters()

  return {
    fetchFacilities,
    refetchData,

    tableColumns,
    perPage,
    currentPage,
    totalFacilities,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFacilityListTable,

    statusFilter,
    statusOptions,
    organizationsFilter,
    organizationsOptions,
    establishmentsFilter,
    establishmentsOptions,

    reject,
    confirm,
    deleteFacility,
  }
}
