import CourseDatesPage from '@/modules/course-date/views/course-dates-page/CourseDatesPage.vue'
import CourseDateDetailPage from '@/modules/course-date/views/course-date-detail-page/CourseDateDetailPage.vue'
import CourseDateDetailRoutes from '@/modules/course-date/views/course-date-detail-page/router'

export default [
  {
    path: '/course-dates',
    name: 'course-date.list',
    component: CourseDatesPage,
    meta: {
      pageTitle: 'Course Dates',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-dates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/course-dates/:slug',
    name: 'course-date.detail',
    redirect: { name: 'course-date.detail.overview' },
    component: CourseDateDetailPage,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-dates',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
    children: [
      ...CourseDateDetailRoutes,
    ],
  },
]
