import { SET_ORGANIZATION_SHOW_SLUG } from '@/modules/organization/store/mutation-types'
import {
  ORGANIZATIONS_FETCH,
  ORGANIZATION_FETCH,
  ORGANIZATION_DELETE,
  CREATE_ORGANIZATION,
  STORE_ORGANIZATION,
  SHOW_ORGANIZATION_BY_SLUG,
  UPDATE_ORGANIZATION,
  FETCH_ORGANIZATION_EMPLOYEES,
  FETCH_ORGANIZATION_FACILITIES,
  FETCH_ORGANIZATION_COURSE_DATES,
  FETCH_ORGANIZATION_EVENTS,
  FETCH_ORGANIZATION_TEAMS,
  FETCH_ORGANIZATION_COACHINGS,
  FETCH_ORGANIZATION_COURSES,
  FETCH_ORGANIZATION_POLLS,
  FETCH_ORGANIZATION_REVIEWS,
  FETCH_ORGANIZATION_FEEDBACKS,
  INVITE_EMPLOYEE,
} from './action-types'
import organizationService from '../services'

export default {
  [ORGANIZATIONS_FETCH]: (context, params) => new Promise((resolve, reject) => {
    organizationService.getOrganizations(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [ORGANIZATION_FETCH]: (context, slug) => new Promise((resolve, reject) => {
    context.commit(SET_ORGANIZATION_SHOW_SLUG, slug)
    organizationService.getOrganization(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_COACHINGS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationCoachings({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_FEEDBACKS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationFeedbacks({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_COURSES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationCourses({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_POLLS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationPolls({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_REVIEWS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationReviews({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_TEAMS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationTeams({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_EMPLOYEES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationEmployees({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_FACILITIES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationFacilities({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_COURSE_DATES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationCourseDates({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ORGANIZATION_EVENTS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.getOrganizationEvents({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [SHOW_ORGANIZATION_BY_SLUG]: (context, slug) => new Promise((resolve, reject) => {
    organizationService.showBySlug(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [ORGANIZATION_DELETE]: (context, organization) => new Promise((resolve, reject) => {
    organizationService.deleteOrganization(organization)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [UPDATE_ORGANIZATION]: (context, organization) => new Promise((resolve, reject) => {
    organizationService.updateOrganization(organization)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [CREATE_ORGANIZATION]: (context, organizationData) => new Promise((resolve, reject) => {
    organizationService.createOrganization(organizationData)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [STORE_ORGANIZATION]: (context, organizationData) => new Promise((resolve, reject) => {
    organizationService.storeOrganization(organizationData)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [INVITE_EMPLOYEE]: (context, email) => new Promise((resolve, reject) => {
    const slug = context.getters.getOrganizationShowSlug
    organizationService.inviteEmployee({ slug, email })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
