<template>
  <b-card
    class="mb-0"
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />

            <b-button
              class="ml-1"
              variant="primary"
              @click="refetchData"
            >
              <span class="text-nowrap">Reload</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refOrganizationListTable"
      class="position-relative"
      :items="fetchOrganizations"
      responsive
      :fields="tableColumns"
      primary-key="uuid"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: Team -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar_thumbnail_url"
              :text="data.item.name"
              :to="{ name: 'team.detail', params: { slug: data.item.slug } }"
            />
          </template>
          <b-link
            :to="{ name: 'team.detail', params: { slug: data.item.slug } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">@{{ data.item.slug }}</small><br>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(my_employee_role)="data">
        <b-badge
          :variant="`light-success`"
          class="text-capitalize"
          pill
        >
          {{ data.item.my_employee_role_label }}
        </b-badge>
      </template>

      <!-- Column: Status -->
      <template #cell(my_employee_status)="data">
        <b-badge
          :variant="`light-${data.item.my_employee_state}`"
          class="text-capitalize"
          pill
        >
          {{ data.item.my_employee_status_label }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <!-- Dropdown -->
          <b-dropdown
            no-caret
            variant="link"
          >

            <template #button-content>
              <feather-icon
                class="align-middle text-body"
                icon="MoreVerticalIcon"
                size="16"
              />
            </template>
            <b-dropdown-item :to="{ name: 'organization.show', params: { slug: data.item.slug } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeRoleToUser(data.item)">
              <span class="align-middle ml-50">Set to user role</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeRoleToAdmin(data.item)">
              <span class="align-middle ml-50">Set to admin role</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirm(data.item)">
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>

            <b-dropdown-item @click="reject(data.item)">
              <span class="align-middle ml-50">Reject</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deactivate(data.item)">
              <span class="align-middle ml-50">Deactivate</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalOrganizations"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BButton, BTable, BBadge, BPagination, BDropdown, BDropdownItem, BMedia, BAvatar, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import useUserOrganizations from './useUserOrganizations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  setup() {
    const {
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrganizationListTable,

      fetchOrganizations,
      refetchData,
      confirm,
      reject,
      deactivate,
      changeRoleToAdmin,
      changeRoleToUser,

      statusOptions,
      statusFilter,
      roleFilter,
      roleOptions,
    } = useUserOrganizations()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrganizationListTable,

      fetchOrganizations,
      refetchData,
      confirm,
      reject,
      deactivate,
      changeRoleToAdmin,
      changeRoleToUser,

      statusOptions,
      statusFilter,
      roleFilter,
      roleOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
