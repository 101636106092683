import { computed, ref, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FETCH_USER_ORGANIZATIONS } from '@/modules/user/store/action-types'
import { UPDATE_EMPLOYEE } from '@/modules/employee/store/action-types'

export default function useUserOrganizations() {
  const toast = useToast()

  const refOrganizationListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'my_employee_role', sortable: true },
    { key: 'my_employee_status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalOrganizations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)
  const roleFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refOrganizationListTable.value ? refOrganizationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrganizations.value,
    }
  })

  const refetchData = () => {
    refOrganizationListTable.value.refresh()
  }
  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchOrganizations = async (ctx, callback) => {
    store
      .dispatch(FETCH_USER_ORGANIZATIONS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalOrganizations.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching user participation list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const confirm = organization => {
    store
      .dispatch(UPDATE_EMPLOYEE, {
        uuid: organization.my_employee_uuid,
        data: {
          action: 'confirm',
        },
      })
      .then(() => {
        refetchData()
      })
  }

  const reject = organization => {
    store
      .dispatch(UPDATE_EMPLOYEE, {
        uuid: organization.my_employee_uuid,
        data: {
          action: 'reject',
        },
      })
      .then(() => {
        refetchData()
      })
  }

  const deactivate = organization => {
    store
      .dispatch(UPDATE_EMPLOYEE, {
        uuid: organization.my_employee_uuid,
        data: {
          action: 'deactivate',
        },
      })
      .then(() => {
        refetchData()
      })
  }

  const changeRoleToUser = organization => {
    store
      .dispatch(UPDATE_EMPLOYEE, {
        uuid: organization.my_employee_uuid,
        data: {
          role: 'user',
        },
      })
      .then(() => {
        refetchData()
      })
  }

  const changeRoleToAdmin = organization => {
    store
      .dispatch(UPDATE_EMPLOYEE, {
        uuid: organization.my_employee_uuid,
        data: {
          role: 'admin',
        },
      })
      .then(() => {
        refetchData()
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Filter
  const statusOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deactivated', value: 'deactivated' },
  ]

  return {
    tableColumns,
    perPage,
    currentPage,
    totalOrganizations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrganizationListTable,

    fetchOrganizations,
    refetchData,
    confirm,
    reject,
    deactivate,
    changeRoleToUser,
    changeRoleToAdmin,

    statusFilter,
    statusOptions,
  }
}
