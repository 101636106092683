import facilityService from '@/modules/facility/services'
import { SET_FACILITY_DETAIL_SLUG } from '@/modules/facility/store/mutation-types'
import {
  FETCH_FACILITIES,
  FETCH_FACILITY_BY_SLUG,
  UPDATE_FACILITY,
  REMOVE_FACILITY,
  FETCH_FACILITIES_FILTERS,
  FETCH_FACILITY_COACHINGS,
  FETCH_FACILITY_EMPLOYEES,
  FETCH_FACILITY_FEEDBACKS,
  FETCH_FACILITY_POLLS,
  FETCH_FACILITY_REVIEWS,
  FETCH_FACILITY_EVENTS, FETCH_FACILITY_COURSE_DATES, FETCH_FACILITY_COURSES, FETCH_FACILITY_FACILITIES,
} from './action-types'

export default {
  [FETCH_FACILITIES]: (context, params) => new Promise((resolve, reject) => {
    facilityService.getFacilities(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_BY_SLUG]: (context, slug) => new Promise((resolve, reject) => {
    context.commit(SET_FACILITY_DETAIL_SLUG, slug)
    facilityService.getFacilityBySlug(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_COACHINGS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getCoachings({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_EMPLOYEES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getEmployees({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_COURSES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getCourses({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_COURSE_DATES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getCourseDates({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_EVENTS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getEvents({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_FACILITIES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getFacilitiesChildren({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_FEEDBACKS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getFeedbacks({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_REVIEWS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getReviews({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITY_POLLS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getFacilityDetailSlug
    facilityService.getPolls({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [UPDATE_FACILITY]: (context, data) => new Promise((resolve, reject) => {
    facilityService.updateFacility(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [REMOVE_FACILITY]: (context, uuid) => new Promise((resolve, reject) => {
    facilityService.deleteFacility(uuid)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_FACILITIES_FILTERS]: () => new Promise((resolve, reject) => {
    facilityService.getFacilitiesFilters()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
