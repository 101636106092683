export const ORGANIZATIONS_FETCH = 'ORGANIZATIONS_FETCH'
export const ORGANIZATION_FETCH = 'ORGANIZATION_FETCH'
export const FETCH_ORGANIZATION_FACILITIES = 'FETCH_ORGANIZATION_FACILITIES'
export const FETCH_ORGANIZATION_EVENTS = 'FETCH_ORGANIZATION_EVENTS'
export const FETCH_ORGANIZATION_COURSE_DATES = 'FETCH_ORGANIZATION_COURSE_DATES'
export const FETCH_ORGANIZATION_EMPLOYEES = 'FETCH_ORGANIZATION_EMPLOYEES'
export const FETCH_ORGANIZATION_TEAMS = 'FETCH_ORGANIZATION_TEAMS'
export const FETCH_ORGANIZATION_COACHINGS = 'FETCH_ORGANIZATION_COACHINGS'
export const FETCH_ORGANIZATION_FEEDBACKS = 'FETCH_ORGANIZATION_FEEDBACKS'
export const FETCH_ORGANIZATION_COURSES = 'FETCH_ORGANIZATION_COURSES'
export const FETCH_ORGANIZATION_REVIEWS = 'FETCH_ORGANIZATION_REVIEWS'
export const FETCH_ORGANIZATION_POLLS = 'FETCH_ORGANIZATION_POLLS'
export const SHOW_ORGANIZATION_BY_SLUG = 'SHOW_ORGANIZATION_BY_SLUG'
export const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const STORE_ORGANIZATION = 'STORE_ORGANIZATION'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const INVITE_EMPLOYEE = 'INVITE_EMPLOYEE'
