<template>
  <div>
    <UserInviteViaEmail />
    <UserCreateForm />
  </div>
</template>

<script>
import UserCreateForm from '@/modules/user/views/user-create-page/components/UserCreateForm.vue'
import UserInviteViaEmail from '@/modules/user/views/user-create-page/components/UserInviteViaEmail.vue'

export default {
  components: {
    UserInviteViaEmail,
    UserCreateForm,
  },
  setup() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
