<template>
  <div>
    <b-card
      class="mb-0"
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            cols="12"
            md="6"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :clearable="false"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refFeedbackListTable"
        class="position-relative"
        :items="fetchFeedbacks"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                :src="data.item.user.avatar_thumbnail_url"
                :text="data.item.user.username"
                :to="{ name: 'users.detail', params: { username: data.item.user.username } }"
                size="32"
              />
            </template>
            <b-link
              :to="{ name: 'users.detail', params: { username: data.item.user.username } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.user.username }}
            </b-link>
            <small class="text-muted">@{{ data.item.user.username }}</small>
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalFeedbacks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BButton,
  BCard, BCol, BFormInput, BLink, BMedia, BPagination, BRow,
  BTable,
} from 'bootstrap-vue'
import { FETCH_FEEDBACKS } from '@/modules/feedback/store/action-types'
import { useToast } from 'vue-toastification/composition'
import { computed, ref, watch } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  name: 'FeedbacksPage',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,

    vSelect,
  },
  setup() {
    const toast = useToast()

    const refFeedbackListTable = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'username', sortable: true },
      { key: 'message', sortable: false },
      { key: 'created_at', sortable: true },
    ]

    const perPage = ref(10)
    const totalFeedbacks = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('created_at')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
      const localItemsCount = refFeedbackListTable.value ? refFeedbackListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalFeedbacks.value,
      }
    })

    const refetchData = () => {
      refFeedbackListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })

    const fetchFeedbacks = (ctx, callback) => {
      store.dispatch(FETCH_FEEDBACKS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
        .then(response => {
          const { data, meta } = response.data

          callback(data)
          // dataMeta.value = meta
          totalFeedbacks.value = meta.total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching feedback list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      fetchFeedbacks,
      refetchData,
      tableColumns,
      perPage,
      currentPage,
      totalFeedbacks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFeedbackListTable,
    }
  },
}
</script>
