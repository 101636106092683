import axios from 'axios'

export default {
  getOrganizations(params) {
    return axios({
      url: 'organizations', params, data: null, method: 'GET',
    })
  },
  getOrganization(slug) {
    return axios({
      url: `organizations/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  getOrganizationTeams({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/teams`, params, data: null, method: 'GET',
    })
  },
  getOrganizationCoachings({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/coachings`, params, data: null, method: 'GET',
    })
  },
  getOrganizationFeedbacks({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/feedbacks`, params, data: null, method: 'GET',
    })
  },
  getOrganizationCourses({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/courses`, params, data: null, method: 'GET',
    })
  },
  getOrganizationPolls({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/polls`, params, data: null, method: 'GET',
    })
  },
  getOrganizationReviews({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/reviews`, params, data: null, method: 'GET',
    })
  },
  getOrganizationEmployees({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/employees`, params, data: null, method: 'GET',
    })
  },
  getOrganizationFacilities({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/facilities`, params, data: null, method: 'GET',
    })
  },
  getOrganizationCourseDates({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/course-dates`, params, data: null, method: 'GET',
    })
  },
  getOrganizationEvents({ slug, params }) {
    return axios({
      url: `organizations/slug/${slug}/events`, params, data: null, method: 'GET',
    })
  },
  showBySlug(slug) {
    return axios({
      url: `organizations/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  createOrganization() {
    return axios({
      url: 'organizations/create', data: null, params: null, method: 'GET',
    })
  },
  storeOrganization(organizationData) {
    return axios({
      url: 'organizations', data: organizationData, method: 'POST',
    })
  },
  inviteEmployee({ slug, email }) {
    return axios({
      url: `organizations/slug/${slug}/invite`, data: email, method: 'POST',
    })
  },
  deleteOrganization(organization) {
    return axios({
      url: `organizations/${organization.uuid}`, params: null, data: null, method: 'DELETE',
    })
  },
  updateOrganization(organization) {
    return axios({
      url: `organizations/${organization.uuid}`, data: organization, method: 'PATCH',
    })
  },
}
