<template>
  <b-tabs
    v-if="userData"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <user-edit-page-general :user-data="userData" />
    </b-tab>
    <!-- general tab -->

  </b-tabs>
</template>

<script>
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { BTabs, BTab } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import UserEditPageGeneral from './components/UserEditPageGeneral.vue'
import { FETCH_USER } from '../../store/action-types'

export default {
  components: {
    BTab,
    BTabs,

    UserEditPageGeneral,
  },
  setup() {
    const userData = ref(null)
    store.dispatch(FETCH_USER, router.currentRoute.params.username)
      .then(response => {
        userData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = null
        }
      })
    return {
      userData,
    }
  },
}
</script>

<style scoped>

</style>
