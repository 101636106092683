<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
      v-if="profileData"
      class="mt-1"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <b-row>
        <b-col
          v-for="socialField in socialInputs"
          :key="socialField.dataField"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="socialField.label"
            :label-for="socialField.dataField"
          >
            <validation-provider
              #default="{ errors }"
              :vid="socialField.dataField"
              :name="socialField.label"
              rules="required"
              immediate
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    size="16"
                    :icon="socialField.icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                  :id="socialField.dataField"
                  v-model="profileData[socialField.dataField]"
                  :placeholder="socialField.label"
                  type="url"
                />
              </b-input-group>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col class="mt-2">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="loading"
            @click="onUpdate"
          >
            <b-spinner
              v-if="loading"
              small
            />
            Save Changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import { PROFILE_UPDATE } from '@/modules/profile/store/action-types'
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const toast = useToast()
    const profile = computed(() => store.getters.getProfile)

    const profileData = ref({
      social_facebook: profile.value.social_facebook,
      social_instagram: profile.value.social_instagram,
      social_snapchat: profile.value.social_snapchat,
      social_twitter: profile.value.social_twitter,
      social_youtube: profile.value.social_youtube,
      social_google: profile.value.social_google,
    })

    const loading = ref(false)

    const socialInputs = [
      {
        icon: 'FacebookIcon',
        dataField: 'social_facebook',
        label: 'Facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'social_instagram',
        label: 'Instagram',
      },
      {
        icon: 'SnapchatIcon',
        dataField: 'social_snapchat',
        label: 'Snapchat',
      },
      {
        icon: 'TwitterIcon',
        dataField: 'social_twitter',
        label: 'Twitter',
      },
      {
        icon: 'YoutubeIcon',
        dataField: 'social_youtube',
        label: 'YouTube',
      },
      {
        icon: 'GoogleIcon',
        dataField: 'social_google',
        label: 'Google',
      },

    ]

    const onUpdate = event => {
      event.preventDefault()
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return
        loading.value = true
        store.dispatch(PROFILE_UPDATE, profileData.value)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Update Profile successfully',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating profile',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            loading.value = false
          })
      })
    }

    return {
      loading,
      onUpdate,
      profileData,
      socialInputs,
    }
  },
}
</script>

<style>

</style>
