import SettingModule from '../SettingModule.vue'
import SettingsPage from '../views/settings-page/SettingsPage.vue'

export default [
  {
    path: '/settings',
    component: SettingModule,
    children: [
      {
        path: '/',
        name: 'settings',
        component: SettingsPage,
      },
    ],
  },
]
