import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from '../modules/auth/store'
import comment from '../modules/comment/store'
import course from '../modules/course/store'
import courseDate from '../modules/course-date/store'
import dashboard from '../modules/dashboard/store'
import establishment from '../modules/establishment/store'
import employee from '../modules/employee/store'
import event from '../modules/event/store'
import facility from '../modules/facility/store'
import faq from '../modules/faq/store'
import feedback from '../modules/feedback/store'
import invitation from '../modules/invitation/store'
import migration from '../modules/migration/store'
import news from '../modules/news/store'
import organization from '../modules/organization/store'
import profile from '../modules/profile/store'
import sportCategory from '../modules/sport-category/store'
import team from '../modules/team/store'
import teamMembership from '../modules/team-membership/store'
import rolePermission from '../modules/role-permission/store'
import setting from '../modules/setting/store'
import user from '../modules/user/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    comment,
    course,
    courseDate,
    dashboard,
    employee,
    establishment,
    event,
    facility,
    faq,
    feedback,
    invitation,
    migration,
    news,
    organization,
    profile,
    sportCategory,
    team,
    teamMembership,
    rolePermission,
    setting,
    user,
  },
  strict: process.env.DEV,
})
