<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="profile.avatar_big_thumbnail_url"
          text="avatar"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ profileData.full_name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->

    <validation-observer
      v-slot="{ handleSubmit, reset }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="reset"
      >
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="username"
                name="Username"
                rules="required"
                immediate
              >
                <b-form-input
                  id="username"
                  v-model="profile.username"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Username"
                  :disabled="true"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: First Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Firstname"
              label-for="first-name"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="first_name"
                name="Firstname"
                immediate
              >
                <b-form-input
                  id="first-name"
                  v-model="profileData.first_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Firstname"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Field: First Name -->

          <!-- Field: Last Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Lastname"
              label-for="last-name"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="last_name"
                name="Lastname"
                immediate
              >
                <b-form-input
                  id="last-name"
                  v-model="profileData.last_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Lastname"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

        <!-- Field: Email -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="user.email"
              type="email"
            />
          </b-form-group>
        </b-col> -->
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="onUpdate"
    >
      <b-spinner
        v-if="loading"
        small
      />
      Save Changes
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm,
  BSpinner,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, computed } from '@vue/composition-api'
import { PROFILE_UPDATE } from '@/modules/profile/store/action-types'
import store from '@/store'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BSpinner,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  setup() {
    // const toast = useToast()
    const profile = computed(() => store.getters.getProfile)

    const profileData = {
      first_name: profile.value.first_name,
      last_name: profile.value.last_name,
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const loading = ref(false)
    // const errors = ref(false)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      profileData.avatar = base64
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      loading,
      profile,
      profileData,
      // onSubmit,
      avatarText,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {
    onUpdate(event) {
      event.preventDefault()
      this.loading = true
      this.$store.dispatch(PROFILE_UPDATE, this.profileData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update Profile successfully',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update Profile Failed',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
          this.$refs.refFormObserver.setErrors(error.response.data.errors)
        })
        .finally(() => {
          this.loading = false
        })
      // self.callStoreProfileUpdate(data);
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
