<template>
  <b-tabs
    v-if="organizationData"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>
      <organization-edit-page-general :organization-data="organizationData" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="Share2Icon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>
      <organization-edit-page-social :organization-data="organizationData" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="MapIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Address</span>
      </template>
      <organization-edit-page-address :organization-data="organizationData" />
    </b-tab>

  </b-tabs>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import { BTabs, BTab } from 'bootstrap-vue'
import { SHOW_ORGANIZATION_BY_SLUG } from '@/modules/organization/store/action-types'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import OrganizationEditPageGeneral from './components/OrganizationEditPageGeneral.vue'
import OrganizationEditPageSocial from './components/OrganizationEditPageSocial.vue'
import OrganizationEditPageAddress from './components/OrganizationEditPageAddress.vue'

export default {
  components: {
    BTab,
    BTabs,

    OrganizationEditPageGeneral,
    OrganizationEditPageSocial,
    OrganizationEditPageAddress,
  },
  setup() {
    const organizationData = ref(null)

    store.dispatch(SHOW_ORGANIZATION_BY_SLUG, router.currentRoute.params.slug)
      .then(response => {
        organizationData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          organizationData.value = null
        }
      })
    return {
      organizationData,
    }
  },
}
</script>

<style scoped>

</style>
