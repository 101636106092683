<template>
  <validation-observer
    ref="refFormObserver"
    #default="{invalid,handleSubmit}"
  >
    <b-form
      class="auth-login-form mt-2"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <!-- email -->
      <b-form-group
        label="Email"
        label-for="email"
      >
        <validation-provider
          #default="{ errors }"
          vid="email"
          name="Email"
          rules="required|email"
        >
          <b-form-input
            id="email"
            v-model="loginData.email"
            :state="errors.length > 0 ? false : null"
            placeholder="Email"
            autocomplete="email"
          />
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>

      <!-- forgot password -->
      <b-form-group
        label="Password"
        label-for="password"
      >
        <validation-provider
          #default="{ errors }"
          vid="password"
          name="Password"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid' : null"
          >
            <b-form-input
              id="password"
              v-model="loginData.password"
              :state="errors.length > 0 ? false : null"
              class="form-control-merge"
              :type="passwordFieldType"
              placeholder="Password"
              autocomplete="current-password"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>

      <!-- submit buttons -->
      <b-button
        type="submit"
        variant="primary"
        block
        :disabled="invalid || loading"
        @click="onSubmit"
      >
        <b-spinner
          v-if="loading"
          small
        />
        <!--        <span class="sr-only">Loading...</span>-->
        Sign in
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { AUTH_LOGIN } from '../../store/action-types'

export default {
  name: 'LoginForm',
  components: {
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return
        this.loading = true
        this.$store
          .dispatch(AUTH_LOGIN, this.loginData)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${response.data.user.full_name
                  || response.data.user.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text:
                  'You have successfully logged. Now you can start to manage!',
              },
            })
            this.$router.push({ name: 'dashboard' })
          })
          .catch(error => {
            if (error.response == null) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Login failed',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Something went wrong',
                },
              })
            } else if (error.response.status === 401) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Login failed',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Invalid email or password',
                },
              })
            } else if (error.response.status === 500) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Login failed',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Something went wrong',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Login Failed',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
              // this.errors = error.response.data.errors;
              this.$refs.refFormObserver.setErrors(error.response.data.errors)
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
  setup() {
    const isDev = computed(() => process.env.VUE_APP_MODE === 'development')
    const loading = ref(false)

    const loginData = {
      email: isDev.value ? 'markus@besportyapp.com' : null,
      password: isDev.value ? 'Password$1' : null,
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    return {
      isDev,
      loginData,
      loading,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      required,
      email,
    }
  },
}
</script>

<style scoped></style>
