import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { USERS_FETCH, UPDATE_USER } from '../../../store/action-types'

export default function useUsers() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  const genderOptions = [
    { label: 'Male', value: 'm' },
    { label: 'Female', value: 'f' },
  ]
  const localeOptions = [
    { label: 'German', value: 'de' },
    { label: 'English', value: 'en' },
    { label: 'Spanish', value: 'es' },
  ]
  const statusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ]
  const emailVerifiedOptions = [
    { label: 'Verified', value: true },
    { label: 'Not verified', value: false },
  ]

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'full_name', sortable: true },
    // { key: 'email', sortable: true },
    { key: 'gender', sortable: true },
    { key: 'locale', sortable: true },
    { key: 'email_verified_at', sortable: true },
    { key: 'last_login_at', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('uuid')
  const isSortDirDesc = ref(true)

  const genderFilter = ref(null)
  const localeFilter = ref(null)
  const statusFilter = ref(null)
  const emailVerifiedFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const deactivate = user => {
    store
      .dispatch(UPDATE_USER, {
        username: user.username,
        is_active: false,
      })
  }

  const activate = user => {
    store
      .dispatch(UPDATE_USER, {
        username: user.username,
        is_active: true,
      })
  }

  const unverifyEmail = user => {
    store
      .dispatch(UPDATE_USER, {
        username: user.username,
        emailVerified: false,
      })
  }

  watch([currentPage, perPage, searchQuery, genderFilter, localeFilter, statusFilter, emailVerifiedFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch(USERS_FETCH, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        gender: genderFilter.value,
        locale: localeFilter.value,
        status: statusFilter.value,
        verified: emailVerifiedFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        // dataMeta.value = meta
        totalUsers.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserEmailVerified = emailVerified => {
    if (emailVerified) return { variant: 'success', icon: 'CheckCircleIcon' }
    return { variant: 'warning', icon: 'InfoIcon' }
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    fetchUsers,

    resolveUserEmailVerified,
    resolveUserStatusVariant,
    refetchData,

    deactivate,
    activate,
    unverifyEmail,

    // Extra Filters
    genderOptions,
    localeOptions,
    statusOptions,
    emailVerifiedOptions,
    genderFilter,
    localeFilter,
    statusFilter,
    emailVerifiedFilter,
  }
}
