export const FETCH_EVENTS = 'FETCH_EVENTS'
export const FETCH_EVENTS_FILTERS = 'FETCH_EVENTS_FILTERS'
export const FETCH_EVENT = 'FETCH_EVENT'
export const FETCH_EVENT_PARTICIPATIONS = 'FETCH_EVENT_PARTICIPATIONS'
export const FETCH_EVENT_INVITATIONS = 'FETCH_EVENT_INVITATIONS'
export const FETCH_EVENT_HOSTERS = 'FETCH_EVENT_HOSTERS'
export const FETCH_EVENT_FOLLOWERS = 'FETCH_EVENT_FOLLOWERS'
export const FETCH_EVENT_SUBSTITUTE_REQUESTS = 'FETCH_EVENT_SUBSTITUTE_REQUESTS'
export const FETCH_EVENT_REACTIONS = 'FETCH_EVENT_REACTIONS'
export const FETCH_EVENT_COACHINGS = 'FETCH_EVENT_COACHINGS'
