import {
  FETCH_EVENT, FETCH_EVENT_COACHINGS,
  FETCH_EVENT_FOLLOWERS, FETCH_EVENT_HOSTERS,
  FETCH_EVENT_INVITATIONS,
  FETCH_EVENT_PARTICIPATIONS, FETCH_EVENT_REACTIONS, FETCH_EVENT_SUBSTITUTE_REQUESTS,
  FETCH_EVENTS,
  FETCH_EVENTS_FILTERS,
} from '@/modules/event/store/action-types'
import eventService from '@/modules/event/services'
import { SET_EVENT_DETAIL_SLUG } from '@/modules/event/store/mutation-types'

export default {
  [FETCH_EVENTS]: (context, params) => new Promise((resolve, reject) => {
    eventService.getEvents(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENTS_FILTERS]: () => new Promise((resolve, reject) => {
    eventService.getEventsFilters()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENT]: (context, slug) => new Promise((resolve, reject) => {
    context.commit(SET_EVENT_DETAIL_SLUG, slug)
    eventService.getEvent(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENT_PARTICIPATIONS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEventDetailSlug
    eventService.getParticipations({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENT_INVITATIONS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEventDetailSlug
    eventService.getInvitations({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENT_HOSTERS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEventDetailSlug
    eventService.getHosters({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENT_FOLLOWERS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEventDetailSlug
    eventService.getFollowers({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENT_SUBSTITUTE_REQUESTS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEventDetailSlug
    eventService.getSubstituteRequests({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENT_REACTIONS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEventDetailSlug
    eventService.getReactions({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_EVENT_COACHINGS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEventDetailSlug
    eventService.getCoachings({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
