<template>
  <b-card
    no-body
  >
    <organization-detail-header-info-card :organization-data="organizationData" />
    <organization-detail-header-tabs :organization-data="organizationData" />
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import OrganizationDetailHeaderInfoCard
  from '@/modules/organization/views/organization-show-page/views/components/OrganizationDetailHeaderInfoCard.vue'
import OrganizationDetailHeaderTabs
  from '@/modules/organization/views/organization-show-page/views/components/OrganizationDetailHeaderTabs.vue'

export default {
  components: {
    OrganizationDetailHeaderTabs,
    OrganizationDetailHeaderInfoCard,
    BCard,
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
  },
}
</script>
