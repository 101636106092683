import FaqModule from '../FaqModule.vue'
import FaqsPage from '../views/faqs-page/FaqsPage.vue'

export default [
  {
    path: '/faqs',
    component: FaqModule,
    children: [
      {
        path: '/',
        name: 'faq.list',
        component: FaqsPage,
        meta: {
          pageTitle: 'Faq list',
          breadcrumb: [
            {
              text: 'Faq list',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
