import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FETCH_SPORT_CATEGORIES, DELETE_SPORT_CATEGORY } from '../../../store/action-types'

export default function useSportCategoryList() {
  // Use toast
  const toast = useToast()

  const refSportCategoryListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'color', sortable: false },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalSportCategories = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refSportCategoryListTable.value ? refSportCategoryListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSportCategories.value,
    }
  })

  const refetchData = () => {
    refSportCategoryListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const statusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
  ]

  const fetchSportCategories = (ctx, callback) => {
    store
      .dispatch(FETCH_SPORT_CATEGORIES, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        // dataMeta.value = meta
        totalSportCategories.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching sport categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const onDelete = sportCategory => {
    store
      .dispatch(DELETE_SPORT_CATEGORY, sportCategory)
      .then(() => {
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error delete sport category',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveSportCategoryStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'rejected') return 'secondary'
    return 'primary'
  }

  return {
    statusOptions,
    fetchSportCategories,
    tableColumns,
    perPage,
    currentPage,
    totalSportCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSportCategoryListTable,

    resolveSportCategoryStatusVariant,
    refetchData,
    onDelete,

    // Extra Filters
    statusFilter,
  }
}
