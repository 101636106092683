import axios from 'axios'

export default {
  get(params) {
    return axios({
      url: 'settings', params, data: null, method: 'GET',
    })
  },
  update(data) {
    return axios({ url: 'settings', data, method: 'PATCH' })
  },
}
