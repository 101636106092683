import axios from 'axios'

export default {
  getSportCategories(params) {
    return axios({
      url: 'sport-categories', params, data: null, method: 'GET',
    })
  },
  showSportCategoryBySlug(slug) {
    return axios({
      url: `sport-categories/${slug}`, data: null, method: 'GET',
    })
  },
  addSportCategory(sportCategoryData) {
    return axios({
      url: 'sport-categories', data: sportCategoryData, method: 'POST',
    })
  },
  deleteSportCategory(sportCategory) {
    return axios({
      url: `sport-categories/${sportCategory.uuid}`, data: null, method: 'DELETE',
    })
  },
  editSportCategory(sportCategory) {
    return axios({
      url: `sport-categories/${sportCategory.uuid}/edit`, data: null, method: 'GET',
    })
  },
  updateSportCategory(sportCategoryData) {
    return axios({
      url: `sport-categories/${sportCategoryData.uuid}`, data: sportCategoryData, method: 'PATCH',
    })
  },
}
