<template>
  <b-card
    class="mb-0"
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />

            <b-button
              class="ml-1"
              variant="primary"
              @click="refetchData"
            >
              <span class="text-nowrap">Reload</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refParicipationListTable"
      class="position-relative"
      :items="fetchParicipations"
      responsive
      :fields="tableColumns"
      primary-key="uuid"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${data.item.state}`"
          class="text-capitalize"
          pill
        >
          {{ data.item.status_label }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <!-- Dropdown -->
          <b-dropdown
            no-caret
            variant="link"
          >

            <template #button-content>
              <feather-icon
                class="align-middle text-body"
                icon="MoreVerticalIcon"
                size="16"
              />
            </template>
            <b-dropdown-item :to="{ name: 'facilities.detail', params: { slug: data.item.slug } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.status_label === 'confirmed'"
              @click="deactivate(data.item)"
            >
              <span class="align-middle ml-50">Deactivate</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status_label === 'inactive'"
              @click="activate(data.item)"
            >
              <span class="align-middle ml-50">Activate</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalParicipations"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BButton, BTable, BBadge, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import useUserParicipations from './useUserParicipations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  setup() {
    const {
      tableColumns,
      perPage,
      currentPage,
      totalParicipations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refParicipationListTable,

      statusOptions,
      statusFilter,
      roleFilter,
      roleOptions,

      fetchParicipations,
      refetchData,
    } = useUserParicipations()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalParicipations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refParicipationListTable,

      fetchParicipations,
      refetchData,

      statusOptions,
      statusFilter,
      roleFilter,
      roleOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
