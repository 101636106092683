export const USERS_FETCH = 'USERS_FETCH'
export const UPDATE_USER = 'UPDATE_USER'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_ABSENCES = 'FETCH_USER_ABSENCES'
export const FETCH_USER_BODY_WEIGHTS = 'FETCH_USER_BODY_WEIGHTS'
export const FETCH_USER_CHECKINS = 'FETCH_USER_CHECKINS'
export const FETCH_USER_COACHINGS = 'FETCH_USER_COACHINGS'
export const FETCH_USER_COMMENTS = 'FETCH_USER_COMMENTS'
export const FETCH_USER_FRIENDS = 'FETCH_USER_FRIENDS'
export const FETCH_USER_FOLLOWERS = 'FETCH_USER_FOLLOWERS'
export const FETCH_USER_FOLLOWINGS = 'FETCH_USER_FOLLOWINGS'
export const FETCH_USER_HOSTINGS = 'FETCH_USER_HOSTINGS'
export const FETCH_USER_ORGANIZATIONS = 'FETCH_USER_ORGANIZATIONS'
export const FETCH_USER_PARTICIPATIONS = 'FETCH_USER_PARTICIPATIONS'
export const FETCH_USER_SUBSTITUTE_REQUESTS = 'FETCH_USER_SUBSTITUTE_REQUESTS'
export const FETCH_USER_TEAMS = 'FETCH_USER_TEAMS'
export const FETCH_USER_SOCIAL_LOGINS = 'FETCH_USER_SOCIAL_LOGINS'
export const USER_ADD = 'USER_ADD'
export const USER_INVITE = 'USER_INVITE'
export const USER_DELETE = 'USER_DELETE'
export const USER_ORGANIZATION_CONFIRM = 'USER_ORGANIZATION_CONFIRM'
export const USER_ORGANIZATION_REJECT = 'USER_ORGANIZATION_REJECT'
export const USER_ORGANIZATION_DEACTIVATE = 'USER_ORGANIZATION_DEACTIVATE'
