<template>
  <b-card
    no-body
  >
    <event-detail-header-info-card :event-data="eventData" />
    <event-detail-header-tabs :event-data="eventData" />
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import EventDetailHeaderInfoCard
  from '@/modules/event/views/event-detail-page/components/EventDetailHeaderInfoCard.vue'
import EventDetailHeaderTabs
  from '@/modules/event/views/event-detail-page/components/EventDetailHeaderTabs.vue'

export default {
  components: {
    EventDetailHeaderTabs,
    EventDetailHeaderInfoCard,
    BCard,
  },
  props: {
    eventData: {
      type: Object,
      required: true,
    },
  },
}
</script>
