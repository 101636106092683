<template>
  <b-card
    no-body
  >
    <course-date-detail-header-info-card :course-date-data="courseDateData" />
    <course-date-detail-header-tabs :course-date-data="courseDateData" />
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import CourseDateDetailHeaderInfoCard
  from '@/modules/course-date/views/course-date-detail-page/components/CourseDateDetailHeaderInfoCard.vue'
import CourseDateDetailHeaderTabs
  from '@/modules/course-date/views/course-date-detail-page/components/CourseDateDetailHeaderTabs.vue'

export default {
  components: {
    CourseDateDetailHeaderTabs,
    CourseDateDetailHeaderInfoCard,
    BCard,
  },
  props: {
    courseDateData: {
      type: Object,
      required: true,
    },
  },
}
</script>
