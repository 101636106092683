import {
  FETCH_MIGRATIONS,
} from './action-types'
import migrationService from '../services'

export default {
  [FETCH_MIGRATIONS]: (context, params) => new Promise((resolve, reject) => {
    migrationService.getMigrations(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
