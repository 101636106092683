export const FETCH_ESTABLISHMENTS = 'FETCH_ESTABLISHMENTS'
export const FETCH_ESTABLISHMENT = 'FETCH_ESTABLISHMENT'
export const FETCH_ESTABLISHMENT_COURSE_DATES = 'FETCH_ESTABLISHMENT_COURSE_DATES'
export const FETCH_ESTABLISHMENT_EMPLOYEES = 'FETCH_ESTABLISHMENT_EMPLOYEES'
export const ESTABLISHMENT_EMPLOYEE_ADD = 'ESTABLISHMENT_EMPLOYEE_ADD'
export const FETCH_ESTABLISHMENT_FACILITIES = 'FETCH_ESTABLISHMENT_FACILITIES'
export const FETCH_ESTABLISHMENT_EVENTS = 'FETCH_ESTABLISHMENT_EVENTS'
export const FETCH_ESTABLISHMENT_COACHINGS = 'FETCH_ESTABLISHMENT_COACHINGS'
export const FETCH_ESTABLISHMENT_COURSES = 'FETCH_ESTABLISHMENT_COURSES'
export const FETCH_ESTABLISHMENT_REVIEWS = 'FETCH_ESTABLISHMENT_REVIEWS'
export const FETCH_ESTABLISHMENT_FEEDBACKS = 'FETCH_ESTABLISHMENT_FEEDBACKS'
export const FETCH_ESTABLISHMENT_POLLS = 'FETCH_ESTABLISHMENT_POLLS'
export const STORE_ESTABLISHMENT = 'STORE_ESTABLISHMENT'
export const UPDATE_ESTABLISHMENT = 'UPDATE_ESTABLISHMENT'
export const REMOVE_ESTABLISHMENT = 'REMOVE_ESTABLISHMENT'
export const FETCH_ESTABLISHMENTS_FILTERS = 'FETCH_ESTABLISHMENTS_FILTERS'
