import axios from 'axios'

export default {
  getFacilities(params) {
    return axios({
      url: 'facilities', params, data: null, method: 'GET',
    })
  },
  getFacilityBySlug(slug) {
    return axios({
      url: `facilities/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  getEmployees({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/employees`, params, data: null, method: 'GET',
    })
  },
  getCoachings({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/coachings`, params, data: null, method: 'GET',
    })
  },
  getFacilitiesChildren({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/facilities`, params, data: null, method: 'GET',
    })
  },
  getCourses({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/courses`, params, data: null, method: 'GET',
    })
  },
  getCourseDates({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/course-dates`, params, data: null, method: 'GET',
    })
  },
  getEvents({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/events`, params, data: null, method: 'GET',
    })
  },
  getFeedbacks({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/feedbacks`, params, data: null, method: 'GET',
    })
  },
  getReviews({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/reviews`, params, data: null, method: 'GET',
    })
  },
  getPolls({ slug, params }) {
    return axios({
      url: `facilities/slug/${slug}/polls`, params, data: null, method: 'GET',
    })
  },
  updateFacility(facility) {
    return axios({
      url: `facilities/${facility.uuid}`, data: facility, method: 'PATCH',
    })
  },
  deleteFacility(uuid) {
    return axios({ url: `facilities/${uuid}`, data: null, method: 'DELETE' })
  },
  getFacilitiesFilters() {
    return axios({
      url: 'facilities/filters', params: null, data: null, method: 'GET',
    })
  },
}
