<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-card-title class="mb-1">
          {{ appTitle }}
        </b-card-title>

        <login-form />
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import { computed } from '@vue/composition-api'
import LoginForm from './components/LoginForm.vue'

export default {
  components: {
    LoginForm,
    BCard,
    BCardTitle,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    const appTitle = computed(() => process.env.VUE_APP_TITLE)
    return {
      appName,
      appLogoImage,
      appTitle,
    }
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-inner::before, .auth-inner::after{
  background-image: none !important;
}
</style>
