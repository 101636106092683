<template>
  <b-card>
    <b-card-header>
      <b-card-title>Organizations</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col>
          <div>
            All:{{ organizationData.allCount }}<br>
            Requested:{{ organizationData.requestedCount }}<br>
            Confirmed:{{ organizationData.confirmedCount }}<br>
            Rejected:{{ organizationData.rejectedCount }}
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
  },
}
</script>
