<template>
  <div>

    <facility-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :organizations-filter.sync="organizationsFilter"
      :organizations-options="organizationsOptions"
      :establishments-filter.sync="establishmentsFilter"
      :establishments-options="establishmentsOptions"
    />

    <b-card
      class="mb-0"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'facility-new'}"
            >
              Add Facility
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refFacilityListTable"
        :items="fetchFacilities"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">

          <b-link
            :to="{ name: 'facility.detail', params: { slug: data.item.slug } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <!-- Column: organization -->
        <template #cell(organization)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.establishment.organization.avatar_thumbnail_url"
                :text="data.item.name"
                :to="{ name: 'organization.edit', params: { slug: data.item.slug } }"
              />
            </template>
            <b-link
              :to="{ name: 'organization.detail', params: { slug: data.item.establishment.place.slug } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <div>{{ data.item.establishment.organization.name }}</div>
              <small>{{ data.item.establishment.organization.slug }}</small>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status_label)="data">
          <b-badge
            :variant="`light-${data.item.state}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.status_label }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <!-- Dropdown -->
            <b-dropdown
              :right="$store.state.appConfig.isRTL"
              no-caret
              variant="link"
            >

              <template #button-content>
                <feather-icon
                  class="align-middle text-body"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="{ name: 'facility.detail', params: { slug: data.item.slug } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status_label !== 'confirmed'"
                @click="confirm(data.item)"
              >
                <span class="align-middle ml-50">Confirm</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status_label !== 'rejected'"
                @click="reject(data.item)"
              >
                <span class="align-middle ml-50">Reject</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalFacilities"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BPagination, BRow, BTable,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FacilityFilters
  from '@/modules/facility/views/facilities-page/components/facility-filters/FacilityFilters.vue'
import useFacilities from '@/modules/facility/views/facilities-page/useFacilities'

export default {
  components: {
    FacilityFilters,
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,
    BPagination,
    BMedia,
    BAvatar,

    vSelect,
  },
  setup() {
    const {
      fetchFacilities,

      tableColumns,
      perPage,
      currentPage,
      totalFacilities,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFacilityListTable,
      refetchData,

      reject,
      confirm,
      deleteFacility,

      statusFilter,
      statusOptions,
      organizationsFilter,
      organizationsOptions,
      establishmentsFilter,
      establishmentsOptions,
    } = useFacilities()

    return {
      fetchFacilities,

      tableColumns,
      perPage,
      currentPage,
      totalFacilities,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFacilityListTable,

      refetchData,

      statusFilter,
      statusOptions,
      organizationsFilter,
      organizationsOptions,
      establishmentsFilter,
      establishmentsOptions,

      reject,
      confirm,
      deleteFacility,
    }
  },
}
</script>

<style lang="scss" scoped>
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
