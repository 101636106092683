import CourseDateDetailOverview
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-page-overview/CourseDateDetailOverview.vue'
import CourseDateDetailSettings
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-page-settings/CourseDateDetailSettings.vue'
import CourseDateDetailCoachings
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-page-coachings/CourseDateDetailCoachings.vue'
import CourseDateDetailParticipationsPage
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-participations-page/CourseDateDetailParticipationsPage.vue'
import CourseDateDetailInvitationsPage
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-invitations-page/CourseDateDetailInvitationsPage.vue'
import CourseDateDetailHosterPage
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-hoster-page/CourseDateDetailHosterPage.vue'
import CourseDateDetailFollowersPage
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-followers-page/CourseDateDetailFollowersPage.vue'
import CourseDateDetailSubstituteRequestsPage
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-substitute-requests-page/CourseDateDetailSubstituteRequestsPage.vue'
import CourseDateDetailReactionsPage
  from '@/modules/course-date/views/course-date-detail-page/views/course-date-detail-reactions-page/CourseDateDetailReactionsPage.vue'

export default [
  {
    path: '',
    name: 'course-date.detail.overview',
    component: CourseDateDetailOverview,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-dates',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'coachings',
    name: 'course-date.detail.coachings',
    component: CourseDateDetailCoachings,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-date',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Employees',
          to: '/course-date/coachings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'participations',
    name: 'course-date.detail.participations',
    component: CourseDateDetailParticipationsPage,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-date',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Participations',
          to: '/course-date/participations',
          active: true,
        },
      ],
    },
  },
  {
    path: 'invitations',
    name: 'course-date.detail.invitations',
    component: CourseDateDetailInvitationsPage,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-date',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Invitations',
          to: '/course-date/invitations',
          active: true,
        },
      ],
    },
  },
  {
    path: 'hosters',
    name: 'course-date.detail.hosters',
    component: CourseDateDetailHosterPage,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-date',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Hosters',
          to: '/course-date/hosters',
          active: true,
        },
      ],
    },
  },
  {
    path: 'followers',
    name: 'course-date.detail.followers',
    component: CourseDateDetailFollowersPage,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-date',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Followers',
          to: '/course-date/followers',
          active: true,
        },
      ],
    },
  },
  {
    path: 'substitute-requests',
    name: 'course-date.detail.substitute-requests',
    component: CourseDateDetailSubstituteRequestsPage,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-date',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Substitute Requests',
          to: '/course-date/substitute-requests',
          active: true,
        },
      ],
    },
  },
  {
    path: 'reactions',
    name: 'course-date.detail.reactions',
    component: CourseDateDetailReactionsPage,
    meta: {
      pageTitle: 'Course Date',
      breadcrumb: [
        {
          text: 'Course Dates',
          to: '/course-date',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Reactions',
          to: '/course-date/reactions',
          active: true,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'course-date.detail.settings',
    component: CourseDateDetailSettings,
    meta: {
      pageTitle: 'CourseDate',
      breadcrumb: [
        {
          text: 'CourseDates',
          to: '/course-dates',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Settings',
          to: '/course-dates/settings',
          active: true,
        },
      ],
    },
  },
]
