import {
  UPDATE_TEAM_MEMBERSHIP,
} from '@/modules/team-membership/store/action-types'
import teamMembershipService from '../services'

export default {
  [UPDATE_TEAM_MEMBERSHIP]: (context, { uuid, data }) => new Promise((resolve, reject) => {
    teamMembershipService.updateTeamMembership({ uuid, data })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
