import {
  FETCH_COURSE_DATE,
  FETCH_COURSE_DATE_COACHINGS, FETCH_COURSE_DATE_FOLLOWERS, FETCH_COURSE_DATE_HOSTERS,
  FETCH_COURSE_DATE_INVITATIONS,
  FETCH_COURSE_DATE_PARTICIPATIONS, FETCH_COURSE_DATE_REACTIONS, FETCH_COURSE_DATE_SUBSTITUTE_REQUESTS,
  FETCH_COURSE_DATES,
  FETCH_COURSE_DATES_FILTERS,
} from '@/modules/course-date/store/action-types'
import courseDateService from '@/modules/course-date/services'
import { SET_COURSE_DATE_DETAIL_SLUG } from '@/modules/course-date/store/mutation-types'

export default {
  [FETCH_COURSE_DATES]: (context, params) => new Promise((resolve, reject) => {
    courseDateService.getCourseDates(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATES_FILTERS]: () => new Promise((resolve, reject) => {
    courseDateService.getCoursesFilters()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATE]: (context, slug) => new Promise((resolve, reject) => {
    context.commit(SET_COURSE_DATE_DETAIL_SLUG, slug)
    courseDateService.getCourseDate(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATE_COACHINGS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getCourseDateDetailSlug
    courseDateService.getCoachings({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATE_PARTICIPATIONS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getCourseDateDetailSlug
    courseDateService.getParticipations({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATE_INVITATIONS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getCourseDateDetailSlug
    courseDateService.getInvitations({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATE_HOSTERS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getCourseDateDetailSlug
    courseDateService.getHosters({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATE_FOLLOWERS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getCourseDateDetailSlug
    courseDateService.getFollowers({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATE_SUBSTITUTE_REQUESTS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getCourseDateDetailSlug
    courseDateService.getSubstituteRequests({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_COURSE_DATE_REACTIONS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getCourseDateDetailSlug
    courseDateService.getReactions({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
