import OrganizationDetailOverview
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-overview/OrganizationDetailOverview.vue'
import OrganizationDetailEmployees
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-employees/OrganizationDetailEmployees.vue'
import OrganizationDetailFacilities
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-facilities/OrganizationDetailFacilities.vue'
import OrganizationDetailEvents
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-events/OrganizationDetailEvents.vue'
import OrganizationDetailSettings
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-settings/OrganizationDetailSettings.vue'
import OrganizationDetailTeams
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-teams/OrganizationDetailTeams.vue'
import OrganizationDetailCourses
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-courses/OrganizationDetailCourses.vue'
import OrganizationDetailCoachings
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-coachings/OrganizationDetailCoachings.vue'
import OrganizationDetailCourseDates
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-course-dates/OrganizationDetailCourseDates.vue'
import OrganizationDetailFeedbacks
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-feedbacks/OrganizationDetailFeedbacks.vue'
import OrganizationDetailReviews
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-reviews/OrganizationDetailReviews.vue'
import OrganizationDetailPolls
  from '@/modules/organization/views/organization-show-page/views/organization-show-page-polls/OrganizationDetailPolls.vue'

export default [
  {
    path: '',
    name: 'organization.detail.overview',
    component: OrganizationDetailOverview,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'employees',
    name: 'organization.detail.employees',
    component: OrganizationDetailEmployees,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Employees',
          to: '/organizations/employees',
          active: true,
        },
      ],
    },
  },
  {
    path: 'coachings',
    name: 'organization.detail.coachings',
    component: OrganizationDetailCoachings,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Coachings',
          to: '/organizations/coachings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'facilities',
    name: 'organization.detail.facilities',
    component: OrganizationDetailFacilities,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Facilities',
          to: '/organizations/facilities',
          active: true,
        },
      ],
    },
  },
  {
    path: 'teams',
    name: 'organization.detail.teams',
    component: OrganizationDetailTeams,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Teams',
          to: '/organizations/teams',
          active: true,
        },
      ],
    },
  },
  {
    path: 'courses',
    name: 'organization.detail.courses',
    component: OrganizationDetailCourses,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Courses',
          to: '/organizations/courses',
          active: true,
        },
      ],
    },
  },
  {
    path: 'course-dates',
    name: 'organization.detail.course-dates',
    component: OrganizationDetailCourseDates,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Course Dates',
          to: '/organizations/course-dates',
          active: true,
        },
      ],
    },
  },
  {
    path: 'events',
    name: 'organization.detail.events',
    component: OrganizationDetailEvents,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Events',
          to: '/organizations/events',
          active: true,
        },
      ],
    },
  },
  {
    path: 'feedbacks',
    name: 'organization.detail.feedbacks',
    component: OrganizationDetailFeedbacks,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Feedbacks',
          to: '/organizations/feedbacks',
          active: true,
        },
      ],
    },
  },
  {
    path: 'reviews',
    name: 'organization.detail.reviews',
    component: OrganizationDetailReviews,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Reviews',
          to: '/organizations/reviews',
          active: true,
        },
      ],
    },
  },
  {
    path: 'polls',
    name: 'organization.detail.polls',
    component: OrganizationDetailPolls,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Polls',
          to: '/organizations/polls',
          active: true,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'organization.detail.settings',
    component: OrganizationDetailSettings,
    meta: {
      pageTitle: 'Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Settings',
          to: '/organizations/settings',
          active: true,
        },
      ],
    },
  },
]
