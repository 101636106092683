<template>
  <div>

    <establishment-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :organizations-filter.sync="organizationsFilter"
      :organizations-options="organizationsOptions"
    />

    <b-card
      class="mb-0"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'establishment-new'}"
            >
              Add Establishment
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refEstablishmentListTable"
        :items="fetchEstablishments"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: place -->
        <template #cell(place)="data">
          <b-link
            :to="{ name: 'establishment.detail', params: { slug: data.item.place.slug } }"
            class="font-weight-bold d-block text-nowrap"
          >
            <div>{{ data.item.place.name }}</div>
            <div>{{ data.item.name }}</div>
            <small>{{ data.item.place.address }}</small>
          </b-link>
        </template>

        <!-- Column: organization -->
        <template #cell(organization)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.organization.avatar_thumbnail_url"
                :text="data.item.name"
                :to="{ name: 'organization.edit', params: { slug: data.item.slug } }"
              />
            </template>
            <b-link
              :to="{ name: 'organization.detail', params: { slug: data.item.organization.slug } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <div>{{ data.item.organization.name }}</div>
              <small>{{ data.item.organization.slug }}</small>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status_label)="data">
          <b-badge
            :variant="`light-${data.item.state}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.status_label }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <!-- Dropdown -->
            <b-dropdown
              :right="$store.state.appConfig.isRTL"
              no-caret
              variant="link"
            >

              <template #button-content>
                <feather-icon
                  class="align-middle text-body"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="{ name: 'establishment.detail', params: { slug: data.item.place.slug } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="deleteEstablishment(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status_label !== 'confirmed'"
                @click="confirm(data.item)"
              >
                <span class="align-middle ml-50">Confirm</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status_label !== 'rejected'"
                @click="reject(data.item)"
              >
                <span class="align-middle ml-50">Reject</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard,
  BRow,
  BCol,
  BFormInput, BDropdown, BDropdownItem, BTable,
  BButton,
  BMedia,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import EstablishmentFilters
  from '@/modules/establishment/views/establishments-page/components/establishment-filters/EstablishmentFilters.vue'
import useEstablishments from '@/modules/establishment/views/establishments-page/useEstablishments'

export default {
  components: {
    EstablishmentFilters,
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    BMedia,
    BAvatar,
    BLink,

    vSelect,
  },
  setup() {
    const {
      fetchEstablishments,
      refetchData,

      tableColumns,
      perPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEstablishmentListTable,

      statusFilter,
      statusOptions,
      organizationsFilter,
      organizationsOptions,
      establishmentsFilter,
      establishmentsOptions,

      confirm,
      reject,
      deleteEstablishment,
    } = useEstablishments()

    return {
      fetchEstablishments,
      refetchData,

      tableColumns,
      perPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEstablishmentListTable,

      statusFilter,
      statusOptions,
      organizationsFilter,
      organizationsOptions,
      establishmentsFilter,
      establishmentsOptions,

      confirm,
      reject,
      deleteEstablishment,
    }
  },
  // mounted() {
  //   if (!this.establishments) {
  //     this.loadEstablishments()
  //   }
  // },
  // methods: {
  //   async loadEstablishments() {
  //     this.showLoading = true
  //     const args = {}
  //     await this.$store.dispatch(FETCH_ESTABLISHMENTS, args)
  //       .then(response => {
  //         this.establishments = response.data.data
  //       })
  //       .finally(() => {
  //         this.showLoading = false
  //       })
  //   },
  // },
}
</script>

<style lang="scss" scoped>
.invoice-filter-select {
  min-width: 190px;

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
}

::v-deep .vs__selected {
  width: 100px;
}
}
</style>
