import axios from 'axios'
import { PROFILE_SET } from '@/modules/profile/store/action-types'
import authService from '../services'
import {
  AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_LOGIN, AUTH_RESET, AUTH_SUCCESS,
} from './action-types'
// import {resetState} from "../../../store";

export default {
  [AUTH_LOGIN]: ({ commit }, data) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST)
    authService.login(data)
      .then(response => {
        const tokenType = response.data.token_type
        const accessToken = response.data.access_token
        const expiresAt = response.data.expires_at
        const { user } = response.data
        const token = `${tokenType} ${accessToken}`
        localStorage.setItem('token', token)
        localStorage.setItem('expiresAt', expiresAt)
        localStorage.setItem('user', JSON.stringify(user))
        axios.defaults.headers.common.Authorization = token
        commit(AUTH_SUCCESS, token)
        commit(PROFILE_SET, { user })
        resolve(response)
      })
      .catch(err => {
        commit(AUTH_ERROR, err)
        // localStorage.removeItem("token");
        reject(err)
      })
  }),
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve, reject) => {
    commit(AUTH_REQUEST)
    return authService.logout()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() => {
        commit(AUTH_RESET)
        localStorage.clear()
      })
  }),
}
