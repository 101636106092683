import { render, staticRenderFns } from "./UserDetailFollowingsPage.vue?vue&type=template&id=230ec1f8&scoped=true&"
import script from "./UserDetailFollowingsPage.vue?vue&type=script&lang=js&"
export * from "./UserDetailFollowingsPage.vue?vue&type=script&lang=js&"
import style0 from "./UserDetailFollowingsPage.vue?vue&type=style&index=0&id=230ec1f8&lang=scss&scoped=true&"
import style1 from "./UserDetailFollowingsPage.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230ec1f8",
  null
  
)

export default component.exports