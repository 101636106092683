import axios from 'axios'

export default {
  getUsers(params) {
    return axios({
      url: 'users', params, data: null, method: 'GET',
    })
  },
  getUserByUsername(username) {
    return axios({
      url: `users/${username}`, params: null, data: null, method: 'GET',
    })
  },
  getParticipationsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/participations`, params, data: null, method: 'GET',
    })
  },
  getSubstituteRequestsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/substitute-requests`, params, data: null, method: 'GET',
    })
  },
  getCoachingsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/coachings`, params, data: null, method: 'GET',
    })
  },
  getCommentsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/comments`, params, data: null, method: 'GET',
    })
  },
  getCheckinsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/checkins`, params, data: null, method: 'GET',
    })
  },
  getOrganizationsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/organizations`, params, data: null, method: 'GET',
    })
  },
  getTeamsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/teams`, params, data: null, method: 'GET',
    })
  },
  getBodyWeightsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/body-weights`, params, data: null, method: 'GET',
    })
  },
  getFriendsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/friends`, params, data: null, method: 'GET',
    })
  },
  getFollowersByUsername({ username, params }) {
    return axios({
      url: `users/${username}/followers`, params, data: null, method: 'GET',
    })
  },
  getFollowingsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/followings`, params, data: null, method: 'GET',
    })
  },
  getHostingsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/hostings`, params, data: null, method: 'GET',
    })
  },
  getSocialLoginsByUsername({ username, params }) {
    return axios({
      url: `users/${username}/social-logins`, params, data: null, method: 'GET',
    })
  },
  getAbsencesByUsername({ username, params }) {
    return axios({
      url: `users/${username}/absences`, params, data: null, method: 'GET',
    })
  },
  storeUser(userData) {
    return axios({
      url: 'users', data: userData, method: 'POST',
    })
  },
  inviteUser(userData) {
    return axios({
      url: 'users/invite', params: userData, method: 'POST',
    })
  },
  updateUser(data) {
    return axios({
      url: `users/${data.username}`, data, method: 'PATCH',
    })
  },
  deleteUser(username) {
    return axios({
      url: `users/${username}`, params: null, data: null, method: 'DELETE',
    })
  },
}
