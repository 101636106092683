<template>
  <div>
    <b-row>
      <b-col>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-card no-body>
              <b-card-header class="flex-column align-items-start">
                <b-card-title>Edit Sport Category</b-card-title>
                <b-card-text class="text-muted mt-25">
                  Type in the information about the sport category
                </b-card-text>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Name"
                          label-for="name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="name"
                            rules="required"
                          >
                            <b-form-input
                              id="name"
                              v-model="sportCategoryData.name"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Color"
                          label-for="color"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="color"
                            rules="required"
                          >
                            <b-form-input
                              id="color"
                              v-model="sportCategoryData.color"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Color"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Status"
                          label-for="status"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="status"
                            rules="required"
                          >
                            <v-select
                              v-model="sportCategoryData.status"
                              :options="statusOptions"
                              :clearable="false"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Status"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          type="submit"
                        >
                          Edit sport category
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div></template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody, BButton, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import {
  UPDATE_SPORTCATEGORY,
  SHOW_SPORTCATEGORY_BY_SLUG,
  EDIT_SPORTCATEGORY,
} from '@/modules/sport-category/store/action-types'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.loadSportCategory()
    this.loadEditSportcategory()
  },
  methods: {
    loadSportCategory() {
      this.$store.dispatch(SHOW_SPORTCATEGORY_BY_SLUG, router.currentRoute.params.slug)
        .then(response => {
          this.sportCategoryData = response.data.data
        })
    },
    loadEditSportcategory() {
      this.$store.dispatch(EDIT_SPORTCATEGORY, router.currentRoute.params.slug)
        .then(response => {
          this.statusOptions = response.data.data
        })
    },
    onSubmit() {
      if (this.$refs.refFormObserver.validate()) {
        this.$store.dispatch(UPDATE_SPORTCATEGORY, {
          name: this.name,
          status: this.status,
        })
          .then(() => {
            this.$router.push({ name: 'sport-category.list' })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Edit sport category failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            // this.errors = error.response.data.errors;
            this.$refs.refFormObserver.setErrors(error.response.data.errors)
          })
      }
    },
  },
  setup() {
    const sportCategoryData = ref(null)
    const statusOptions = ref([])
    const errors = ref([])

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      sportCategoryData,
      statusOptions,

      errors,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      required,
      integer,
    }
  },
}
</script>

<style scoped>

</style>
