import EstablishmentDetailOverview
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-overview/EstablishmentDetailOverview.vue'
import EstablishmentDetailEmployees
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-employees/EstablishmentDetailEmployees.vue'
import EstablishmentDetailFacilities
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-facilities/EstablishmentDetailFacilities.vue'
import EstablishmentDetailCourseDates
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-course-dates/EstablishmentDetailCourseDates.vue'
import EstablishmentDetailEvents
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-events/EstablishmentDetailEvents.vue'
import EstablishmentDetailSettings
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-settings/EstablishmentDetailSettings.vue'
import EstablishmentDetailCoachings
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-coachings/EstablishmentDetailCoachings.vue'
import EstablishmentDetailCourses
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-show-page-courses/EstablishmentDetailCourses.vue'
import EstablishmentDetailFeedbacks
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-show-page-feedbacks/EstablishmentDetailFeedbacks.vue'
import EstablishmentDetailReviews
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-show-page-reviews/EstablishmentDetailReviews.vue'
import EstablishmentDetailPolls
  from '@/modules/establishment/views/establishment-detail-page/views/establishment-detail-page-polls/EstablishmentDetailPolls.vue'

export default [
  {
    path: '',
    name: 'establishment.detail.overview',
    component: EstablishmentDetailOverview,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'employees',
    name: 'establishment.detail.employees',
    component: EstablishmentDetailEmployees,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Employees',
          to: '/establishments/employees',
          active: true,
        },
      ],
    },
  },
  {
    path: 'coachings',
    name: 'establishment.detail.coachings',
    component: EstablishmentDetailCoachings,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishment',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Employees',
          to: '/establishment/coachings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'facilities',
    name: 'establishment.detail.facilities',
    component: EstablishmentDetailFacilities,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Facilities',
          to: '/establishments/facilities',
          active: true,
        },
      ],
    },
  },
  {
    path: 'courses',
    name: 'establishment.detail.courses',
    component: EstablishmentDetailCourses,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Courses',
          to: '/establishments/courses',
          active: true,
        },
      ],
    },
  },
  {
    path: 'course-dates',
    name: 'establishment.detail.course-dates',
    component: EstablishmentDetailCourseDates,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Courses',
          to: '/establishments/course-dates',
          active: true,
        },
      ],
    },
  },
  {
    path: 'events',
    name: 'establishment.detail.events',
    component: EstablishmentDetailEvents,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Events',
          to: '/establishments/events',
          active: true,
        },
      ],
    },
  },
  {
    path: 'feedbacks',
    name: 'establishment.detail.feedbacks',
    component: EstablishmentDetailFeedbacks,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Feedbacks',
          to: '/establishments/feedbacks',
          active: true,
        },
      ],
    },
  },
  {
    path: 'reviews',
    name: 'establishment.detail.reviews',
    component: EstablishmentDetailReviews,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Reviews',
          to: '/establishments/reviews',
          active: true,
        },
      ],
    },
  },
  {
    path: 'polls',
    name: 'establishment.detail.polls',
    component: EstablishmentDetailPolls,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Polls',
          to: '/establishments/polls',
          active: true,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'establishment.detail.settings',
    component: EstablishmentDetailSettings,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Settings',
          to: '/establishments/settings',
          active: true,
        },
      ],
    },
  },
]
