import {
  FETCH_STATISTICS,
} from '@/modules/dashboard/store/action-types'
import dashboardService from '@/modules/dashboard/services'

export default {
  [FETCH_STATISTICS]: () => new Promise((resolve, reject) => {
    dashboardService.getStatistics()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
