import { render, staticRenderFns } from "./EventDetailReactionsPage.vue?vue&type=template&id=57f6102a&scoped=true&"
import script from "./EventDetailReactionsPage.vue?vue&type=script&lang=js&"
export * from "./EventDetailReactionsPage.vue?vue&type=script&lang=js&"
import style0 from "./EventDetailReactionsPage.vue?vue&type=style&index=0&id=57f6102a&lang=scss&scoped=true&"
import style1 from "./EventDetailReactionsPage.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f6102a",
  null
  
)

export default component.exports