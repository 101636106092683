import EstablishmentsPage from '@/modules/establishment/views/establishments-page/EstablishmentsPage.vue'
import EstablishmentDetailPage from '@/modules/establishment/views/establishment-detail-page/EstablishmentDetailPage.vue'
import EstablishmentDetailRoutes from '@/modules/establishment/views/establishment-detail-page/router'
import EstablishmentCreatePage from '@/modules/establishment/views/establishment-create-page/EstablishmentCreatePage.vue'

export default [
  {
    path: '/establishments',
    name: 'establishment.list',
    component: EstablishmentsPage,
    meta: {
      pageTitle: 'Establishments',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/establishments/create',
    name: 'establishment-new',
    component: EstablishmentCreatePage,
    meta: {
      pageTitle: 'Establishment Add',
      breadcrumb: [
        {
          text: 'Establishment List',
          to: '/establishments',
        },
        {
          text: 'Establishment Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/establishments/:slug',
    name: 'establishment.detail',
    redirect: { name: 'establishment.detail.overview' },
    component: EstablishmentDetailPage,
    meta: {
      pageTitle: 'Establishment',
      breadcrumb: [
        {
          text: 'Establishments',
          to: '/establishments',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
    children: [
      ...EstablishmentDetailRoutes,
    ],
  },
]
