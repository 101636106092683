<template>
  <b-card
    no-body
  >
    <user-detail-header-info-card :user-data="userData" />
    <user-detail-header-tabs :user-data="userData" />
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import UserDetailHeaderInfoCard
  from '@/modules/user/views/user-detail-page/components/UserDetailHeaderInfoCard.vue'
import UserDetailHeaderTabs
  from '@/modules/user/views/user-detail-page/components/UserDetailHeaderTabs.vue'

export default {
  components: {
    UserDetailHeaderTabs,
    UserDetailHeaderInfoCard,
    BCard,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>
