var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"flex-column align-items-start"},[_c('b-card-title',[_vm._v("Create User")]),_c('b-card-text',{staticClass:"text-muted mt-25"},[_vm._v(" Type in the information about the user ")])],1),_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"vid":"username","name":"Username","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"autocomplete":"username","placeholder":"Username"},model:{value:(_vm.userData.username),callback:function ($$v) {_vm.$set(_vm.userData, "username", $$v)},expression:"userData.username"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Firstname","label-for":"first_name"}},[_c('validation-provider',{attrs:{"vid":"first_name","name":"Firstname","rules":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"placeholder":"Firstname"},model:{value:(_vm.userData.first_name),callback:function ($$v) {_vm.$set(_vm.userData, "first_name", $$v)},expression:"userData.first_name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Lastname","label-for":"last_name"}},[_c('validation-provider',{attrs:{"vid":"last_name","name":"Lastname","rules":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"placeholder":"Lastname"},model:{value:(_vm.userData.last_name),callback:function ($$v) {_vm.$set(_vm.userData, "last_name", $$v)},expression:"userData.last_name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required|email","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|password","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.password1FieldType,"state":errors.length > 0 ? false:null,"autocomplete":"new-password","placeholder":"············"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"password_confirm"}},[_c('validation-provider',{attrs:{"vid":"c_password","name":"Confirm Password","rules":"required|confirmed:password","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password_confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"confirm-password","autocomplete":"new-password","placeholder":"············"},model:{value:(_vm.userData.password_confirm),callback:function ($$v) {_vm.$set(_vm.userData, "password_confirm", $$v)},expression:"userData.password_confirm"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"disabled":invalid || _vm.loading},on:{"click":_vm.onSubmit}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Create ")],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }