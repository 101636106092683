<template>
  <div>

    <sport-category-list-add-new
      :is-add-new-sport-category-sidebar-active.sync="isAddNewSportCategorySidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <sport-category-page-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            cols="12"
            md="6"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :clearable="false"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewSportCategorySidebarActive = true"
              >
                <span class="text-nowrap">Add Sport category</span>
              </b-button>
              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refSportCategoryListTable"
        class="position-relative"
        :items="fetchSportCategories"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveSportCategoryStatusVariant(data.item.status_label)}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.status_label }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`sport-category-row-${data.item.uuid}-edit-icon`"
              class="cursor-pointer mx-1"
              icon="EditIcon"
              size="16"
              @click="$router.push({ name: 'sport-category.edit', params: { slug: data.item.slug }})"
            />
            <b-tooltip
              :target="`sport-category-row-${data.item.uuid}-edit-icon`"
              class="cursor-pointer"
              title="Edit"
            />

            <!-- Dropdown -->
            <b-dropdown
              :right="$store.state.appConfig.isRTL"
              no-caret
              variant="link"
            >

              <template #button-content>
                <feather-icon
                  class="align-middle text-body"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>

              <b-dropdown-item :to="{ name: 'sport-category.edit', params: { slug: data.item.slug } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="onDelete(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalSportCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol, BDropdown, BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import SportCategoryPageListFilters from './SportCategoryPageListFilters.vue'
import useSportCategoryList from './useSportCategoryPageList'
import SportCategoryListAddNew from './SportCategoryListAddNew.vue'

export default {
  name: 'SportCategoryPageList',
  components: {
    SportCategoryPageListFilters,
    SportCategoryListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  setup() {
    const isAddNewSportCategorySidebarActive = ref(false)

    const {
      statusOptions,
      fetchSportCategories,
      tableColumns,
      perPage,
      currentPage,
      totalSportCategories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSportCategoryListTable,
      refetchData,
      onDelete,

      // UI
      resolveSportCategoryStatusVariant,

      // Extra Filters
      statusFilter,
    } = useSportCategoryList()

    return {

      // Sidebar
      isAddNewSportCategorySidebarActive,

      fetchSportCategories,
      tableColumns,
      perPage,
      currentPage,
      totalSportCategories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSportCategoryListTable,
      refetchData,
      onDelete,

      // UI
      resolveSportCategoryStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style scoped>

</style>
