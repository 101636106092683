import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn } from '@/auth/utils'

import DashboardPage from '@/views/dashboard-page/DashboardPage.vue'
import AuthRoutes from '../modules/auth/router'
import CourseRoutes from '../modules/course/router'
import CourseDateRoutes from '../modules/course-date/router'
import DashboardRoutes from '../modules/dashboard/router'
import EstablishmentRoutes from '../modules/establishment/router'
import EventRoutes from '../modules/event/router'
import FacilityRoutes from '../modules/facility/router'
import FaqRoutes from '../modules/faq/router'
import FeedbackRoutes from '../modules/feedback/router'
import InvitationRoutes from '../modules/invitation/router'
import MigrationRoutes from '../modules/migration/router'
import NewsRoutes from '../modules/news/router'
import ProfileRoutes from '../modules/profile/router'
import OrganizationRoutes from '../modules/organization/router'
import SettingRoutes from '../modules/setting/router'
import RolePermissionRoutes from '../modules/role-permission/router'
import SportCategoryRoutes from '../modules/sport-category/router'
import TeamRoutes from '../modules/team/router'
import UserRoutes from '../modules/user/router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'dashboard' } },
    {
      path: '/',
      name: 'dashboard',
      component: DashboardPage,
    },
    ...AuthRoutes,
    ...CourseRoutes,
    ...CourseDateRoutes,
    ...DashboardRoutes,
    ...EstablishmentRoutes,
    ...EventRoutes,
    ...FacilityRoutes,
    ...FaqRoutes,
    ...FeedbackRoutes,
    ...InvitationRoutes,
    ...MigrationRoutes,
    ...NewsRoutes,
    ...OrganizationRoutes,
    ...ProfileRoutes,
    ...RolePermissionRoutes,
    ...SettingRoutes,
    ...SportCategoryRoutes,
    ...TeamRoutes,
    ...UserRoutes,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'dashboard' })
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
