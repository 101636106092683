<template>
  <section>
    <b-row class="match-height">
      <b-col>
        <statistics-organizations
          v-if="organizations"
          :organization-data="organizations"
        />
      </b-col>
      <b-col>
        <statistics-users
          v-if="users"
          :user-data="users"
        />
      </b-col>
      <b-col>
        <statistics-teams
          v-if="teams"
          :team-data="teams"
        />
      </b-col>
      <b-col>
        <statistics-feedbacks
          v-if="feedbacks"
          :feedback-data="feedbacks"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <PendingEstablishmentsCard />
      </b-col>
      <b-col>
        <PendingUsersCard />
      </b-col>
      <b-col>
        <PendingOrganizationsCard />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <NewOrganizationsCard />
      </b-col>
      <b-col>
        <NewUsersCard />
      </b-col>
      <b-col>
        <NewFeedbackCard />
      </b-col>
    </b-row>
  </section>
</template>
<script>

import { BCol, BRow } from 'bootstrap-vue'
import { FETCH_STATISTICS } from '@/modules/dashboard/store/action-types'
import { ref } from '@vue/composition-api'
import StatisticsUsers from '@/modules/user/components/StatisticsUsers.vue'
import StatisticsOrganizations from '@/modules/organization/components/StatisticsOrganizations.vue'
import StatisticsTeams from '@/modules/team/components/StatisticsTeams.vue'
import StatisticsFeedbacks from '@/modules/feedback/components/StatisticsFeedbacks.vue'
import PendingEstablishmentsCard from '@/modules/establishment/components/PendingEstablishmentsCard.vue'
import PendingUsersCard from '@/modules/user/components/PendingUsersCard.vue'
import PendingOrganizationsCard from '@/modules/organization/components/PendingOrganizationsCard.vue'
import NewFeedbackCard from '@/modules/feedback/components/NewFeedbackCard.vue'
import NewOrganizationsCard from '@/modules/organization/components/NewOrganizationsCard.vue'
import NewUsersCard from '@/modules/user/components/NewUsersCard.vue'

export default {
  name: 'DashboardPage',
  components: {
    NewUsersCard,
    NewOrganizationsCard,
    NewFeedbackCard,
    PendingOrganizationsCard,
    PendingUsersCard,
    PendingEstablishmentsCard,
    StatisticsOrganizations,
    StatisticsUsers,
    StatisticsTeams,
    StatisticsFeedbacks,
    BRow,
    BCol,
  },
  mounted() {
    this.fetchStatistics()
  },
  setup() {
    const absences = ref(null)
    const bodyWeights = ref(null)
    const businessHours = ref(null)
    const checkins = ref(null)
    const coachings = ref(null)

    const feedbacks = ref(null)
    const organizations = ref(null)
    const teams = ref(null)
    const users = ref(null)

    return {

      absences,
      bodyWeights,
      businessHours,
      checkins,
      coachings,

      feedbacks,
      organizations,
      teams,
      users,
    }
  },
  methods: {
    async fetchStatistics() {
      this.$store
        .dispatch(FETCH_STATISTICS)
        .then(response => {
          this.absences = response.data.absences
          this.bodyWeights = response.data.bodyWeights
          this.businessHours = response.data.businessHours
          this.checkins = response.data.checkins

          this.feedbacks = response.data.feedbacks
          this.organizations = response.data.organizations
          this.teams = response.data.teams
          this.users = response.data.users
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style scoped></style>
