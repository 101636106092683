import { render, staticRenderFns } from "./UserDetailPage.vue?vue&type=template&id=2e3c2551&scoped=true&"
import script from "./UserDetailPage.vue?vue&type=script&lang=js&"
export * from "./UserDetailPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e3c2551",
  null
  
)

export default component.exports