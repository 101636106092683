<template>
  <b-card>
    <validation-observer
      ref="refFormObserver"
      #default="{invalid,handleSubmit}"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                vid="email"
                name="Email"
                rules="required|email"
                immediate
              >
                <b-form-input
                  id="email-invite"
                  v-model="emailData"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Email"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid || loading"
          @click="onSubmit"
        >
          <b-spinner
            v-if="loading"
            small
          />
          Invite
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BButton,
  BCard, BCol, BForm, BFormGroup,
  BFormInput, BFormInvalidFeedback, BRow, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { computed, ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { USER_INVITE } from '@/modules/user/store/action-types'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
  },
  setup(props, { refs }) {
    const toast = useToast()
    const isDev = computed(() => process.env.VUE_APP_MODE === 'development')
    const loading = ref(false)

    const emailData = ref(isDev.value ? 'testuser1@besportyapp.com' : null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    const onSubmit = async () => {
      refs.refFormObserver.validate().then(success => {
        if (!success) return
        loading.value = true
        store.dispatch(USER_INVITE, { email: emailData.value })
          .then(() => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Invite User successfully',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Invite User failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            refs.refFormObserver.setErrors(error.response.data.errors)
          })
          .finally(() => {
            loading.value = false
          })
      })
    }

    return {
      emailData,
      loading,
      onSubmit,
      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style scoped>

</style>
