<template>
  <b-card-actions
    ref="refCardObserver"
    :no-actions="true"
    @refresh="loadSettings"
  >
    <b-form
      @submit.prevent
    >
      <b-row>

        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              v-model="settingData.is_register_enabled"
              name="is_register_enabled"
            >
              Enable Register
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              v-model="settingData.is_login_enabled"
              name="is_login_enabled"
            >
              Enable Login
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              v-model="settingData.is_business_login_enabled"
              name="is_business_login_enabled"
            >
              Enable Business-Login
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
            @click="onSubmit"
          >
            <b-spinner
              v-if="loading"
              small
            />
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="onReset"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card-actions>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormCheckbox, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { SETTINGS_FETCH, SETTINGS_UPDATE } from '@/modules/setting/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      settingData: {},
      loading: false,
    }
  },
  computed: {
    // setting() {
    //   return this.$store.getters.setting
    // },
  },
  mounted() {
    this.loadSettings()
  },
  methods: {
    async loadSettings() {
      // const id = +this.$route.params.id;
      this.$refs.refCardObserver.showLoading = true
      this.$store.dispatch(SETTINGS_FETCH)
        .then(response => {
          this.settingData = response.data.data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Login Failed',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
        .finally(() => {
          this.$refs.refCardObserver.showLoading = false
        })
    },
    onReset(event) {
      event.preventDefault()
    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      this.$store.dispatch(SETTINGS_UPDATE, this.settingData)
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Login Failed',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
