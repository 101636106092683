import { FETCH_EVENTS, FETCH_EVENTS_FILTERS } from '@/modules/event/store/action-types'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEvents() {
  const toast = useToast()

  const refEventListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'start_time', sortable: true },
    // { key: 'establishment', sortable: true },
    // { key: 'facility', sortable: true },
    { key: 'sport_category.name', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalEvents = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)

  const timeOptions = ref([])

  // [
  //   { label: 'Today', value: 'today' },
  //   { label: 'Tomorrow', value: 'tomorrow' },
  //   { label: 'This week', value: 'this-week' },
  //   { label: 'This weekend', value: 'this-weekend' },
  //   { label: 'Next week', value: 'next-week' },
  //   { label: 'This month', value: 'this-month' },
  //   { label: 'Next month', value: 'next-month' },
  //   { label: 'Past', value: 'past' },
  //   { label: 'Upcoming', value: 'upcoming' },
  //   { label: 'All', value: 'all' },
  // ]
  const timeFilter = ref(null)

  const sportCategoryFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refEventListTable.value ? refEventListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEvents.value,
    }
  })

  const refetchData = () => {
    refEventListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, timeFilter, sportCategoryFilter], () => {
    refetchData()
  })

  const fetchEvents = (ctx, callback) => {
    store
      .dispatch(FETCH_EVENTS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        time: timeFilter.value,
        status: statusFilter.value,
        sportCategory: sportCategoryFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalEvents.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching course date list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchEventsFilters = async () => {
    store.dispatch(FETCH_EVENTS_FILTERS)
      .then(response => {
        timeOptions.value = response.data.times
      })
  }

  fetchEventsFilters()

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Filter
  const statusOptions = [
    { label: 'Draft', value: 'draft' },
    { label: 'Pending', value: 'pending' },
    { label: 'Published', value: 'published' },
  ]

  const sportCategoryOptions = [
    { label: 'Fitness', value: 'fitness' },
  ]

  return {
    tableColumns,
    perPage,
    currentPage,
    totalEvents,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEventListTable,

    fetchEvents,
    refetchData,

    statusFilter,
    statusOptions,
    timeFilter,
    timeOptions,
    sportCategoryFilter,
    sportCategoryOptions,
  }
}
