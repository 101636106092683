export const FETCH_FACILITIES = 'FETCH_FACILITIES'
export const FETCH_FACILITY_BY_SLUG = 'FETCH_FACILITY_BY_SLUG'
export const FETCH_FACILITY_COACHINGS = 'FETCH_FACILITY_COACHINGS'
export const FETCH_FACILITY_COURSE_DATES = 'FETCH_FACILITY_COURSE_DATES'
export const FETCH_FACILITY_EMPLOYEES = 'FETCH_FACILITY_EMPLOYEES'
export const FETCH_FACILITY_REVIEWS = 'FETCH_FACILITY_REVIEWS'
export const FETCH_FACILITY_EVENTS = 'FETCH_FACILITY_EVENTS'
export const FETCH_FACILITY_FACILITIES = 'FETCH_FACILITY_FACILITIES'
export const FETCH_FACILITY_COURSES = 'FETCH_FACILITY_COURSES'
export const FETCH_FACILITY_FEEDBACKS = 'FETCH_FACILITY_FEEDBACKS'
export const FETCH_FACILITY_POLLS = 'FETCH_FACILITY_POLLS'
export const UPDATE_FACILITY = 'UPDATE_FACILITY'
export const REMOVE_FACILITY = 'REMOVE_FACILITY'
export const FETCH_FACILITIES_FILTERS = 'FETCH_FACILITIES_FILTERS'
