var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"flex-column align-items-start"},[_c('b-card-title',[_vm._v("Edit Sport Category")]),_c('b-card-text',{staticClass:"text-muted mt-25"},[_vm._v(" Type in the information about the sport category ")])],1),_c('b-card-body',[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Name"},model:{value:(_vm.sportCategoryData.name),callback:function ($$v) {_vm.$set(_vm.sportCategoryData, "name", $$v)},expression:"sportCategoryData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Color","label-for":"color"}},[_c('validation-provider',{attrs:{"name":"color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"color","state":errors.length > 0 ? false:null,"placeholder":"Color"},model:{value:(_vm.sportCategoryData.color),callback:function ($$v) {_vm.$set(_vm.sportCategoryData, "color", $$v)},expression:"sportCategoryData.color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusOptions,"clearable":false,"state":errors.length > 0 ? false:null,"placeholder":"Status"},model:{value:(_vm.sportCategoryData.status),callback:function ($$v) {_vm.$set(_vm.sportCategoryData, "status", $$v)},expression:"sportCategoryData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Edit sport category ")])],1)],1)],1)],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }