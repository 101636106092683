<template>

  <div>

    <sidebar-user-add
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <user-page-filters
      :gender-filter.sync="genderFilter"
      :gender-options="genderOptions"
      :locale-filter.sync="localeFilter"
      :locale-options="localeOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :email-verified-filter.sync="emailVerifiedFilter"
      :email-verified-options="emailVerifiedOptions"
    />

    <!-- Table Container Card -->
    <b-card
      class="mb-0"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <!--            <b-button-->
            <!--              variant="primary"-->
            <!--              @click="isAddNewUserSidebarActive = true"-->
            <!--            >-->
            <!--              Add User-->
            <!--            </b-button>-->

            <b-button
              variant="primary"
              @click="onUserCreate"
            >
              Create User
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                :src="data.item.avatar_thumbnail_url"
                :text="data.item.username"
                :to="{ name: 'user.detail', params: { username: data.item.username } }"
                size="32"
              />
            </template>
            <b-link
              :to="{ name: 'user.detail', params: { username: data.item.username } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.username }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <template #cell(email_verified_at)="data">
          <b-avatar
            :id="`user-row-${data.item.email_verified_at}`"
            :variant="`light-${resolveUserEmailVerified(data.item.email_verified_at).variant}`"
            size="32"
          >
            <feather-icon
              :icon="resolveUserEmailVerified(data.item.email_verified_at).icon"
            />
          </b-avatar>
        </template>

        <!-- Column: Gender -->
        <template #cell(gender)="data">
          {{ data.item.gender_label }}
        </template>

        <!-- Column: Locale -->
        <template #cell(locale)="data">
          {{ data.item.locale_label }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveUserStatusVariant(data.item.is_active_label)}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.is_active_label }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`user-row-${data.item.uuid}-send-icon`"
              class="cursor-pointer mx-1"
              icon="SendIcon"
              size="16"
            />
            <b-tooltip
              :target="`user-row-${data.item.uuid}-send-icon`"
              class="cursor-pointer"
              title="Send Message"
            />

            <!-- Dropdown -->
            <b-dropdown
              :right="$store.state.appConfig.isRTL"
              no-caret
              variant="link"
            >

              <template #button-content>
                <feather-icon
                  class="align-middle text-body"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="{ name: 'users.detail', params: { username: data.item.username } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'users.edit', params: { username: data.item.username } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deactivate(data.item)">
                <span class="align-middle ml-50">Deactivate</span>
              </b-dropdown-item>
              <b-dropdown-item @click="activate(data.item)">
                <span class="align-middle ml-50">Activate</span>
              </b-dropdown-item>
              <b-dropdown-item @click="unverifyEmail(data.item)">
                <span class="align-middle ml-50">Unverify email</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import SidebarUserAdd from '@/modules/user/components/sidebars/SidebarUserAdd.vue'
import UserPageFilters from './components/UserPageFilters.vue'
// eslint-disable-next-line import/no-cycle
import useUsers from './components/useUsers'

export default {
  name: 'UsersPage',
  components: {
    UserPageFilters,
    SidebarUserAdd,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BTooltip,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  setup(props, context) {
    const router = context.root.$router
    // const route = context.root.$route;

    const isAddNewUserSidebarActive = ref(false)

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      fetchUsers,
      refetchData,

      deactivate,
      activate,
      unverifyEmail,

      // UI
      resolveUserEmailVerified,
      resolveUserStatusVariant,

      // Extra Filters
      genderOptions,
      localeOptions,
      statusOptions,
      emailVerifiedOptions,
      genderFilter,
      localeFilter,
      statusFilter,
      emailVerifiedFilter,
    } = useUsers()

    const onUserCreate = () => {
      router.push({ name: 'user.create' })
    }

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      fetchUsers,
      refetchData,

      deactivate,
      activate,
      unverifyEmail,

      // UI
      resolveUserEmailVerified,
      resolveUserStatusVariant,

      // Extra Filters
      genderOptions,
      localeOptions,
      statusOptions,
      emailVerifiedOptions,

      genderFilter,
      localeFilter,
      statusFilter,
      emailVerifiedFilter,

      onUserCreate,
    }
  },
}
</script>

<style scoped>

</style>
