import {
  PROFILE_UPDATE, PROFILE_UPDATE_FAILURE, PROFILE_SET, PROFILE_RESET,
} from './action-types'
import { getDefaultState } from './state'

export default {
  [PROFILE_SET](state, { user }) {
    state.profile = user
  },
  // [PROFILE_UPDATE](state, payload) {
  //     Object.assign(state.user, {
  //         [field]: value
  //     });
  // }
  [PROFILE_UPDATE](state, { field, value }) {
    Object.assign(state.user, {
      [field]: value,
    })
  },
  [PROFILE_UPDATE_FAILURE](state, { errors }) {
    state.errors = errors
  },
  [PROFILE_RESET](state) {
    Object.assign(state, getDefaultState())
    // const s = getDefaultState()
    //     Object.keys(s).forEach(key => {
    //     state[key] = s[key]
    // })
  },
}
