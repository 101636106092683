import EventsPage from '@/modules/event/views/events-page/EventsPage.vue'
import EventDetailPage from '@/modules/event/views/event-detail-page/EventDetailPage.vue'
import EventDetailRoutes from '@/modules/event/views/event-detail-page/router'

export default [
  {
    path: '/events',
    name: 'event.list',
    component: EventsPage,
    meta: {
      pageTitle: 'Events',
      breadcrumb: [
        {
          text: 'Events',
          to: '/events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events/:slug',
    name: 'event.detail',
    redirect: { name: 'event.detail.overview' },
    component: EventDetailPage,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/events',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
    children: [
      ...EventDetailRoutes,
    ],
  },
]
