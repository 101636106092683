import {
  SETTINGS_FETCH,
  SETTINGS_UPDATE,
} from './action-types'
import settingsService from '../services'

export default {
  [SETTINGS_FETCH]: () => new Promise((resolve, reject) => {
    settingsService.get()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [SETTINGS_UPDATE]: (context, data) => new Promise((resolve, reject) => {
    settingsService.update(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
