import {
  FETCH_SPORT_CATEGORIES,
  ADD_SPORT_CATEGORY,
  DELETE_SPORT_CATEGORY, UPDATE_SPORTCATEGORY,
  SHOW_SPORTCATEGORY_BY_SLUG, EDIT_SPORTCATEGORY,
} from './action-types'
import sportCategoryService from '../services'

export default {
  [FETCH_SPORT_CATEGORIES]: (context, params) => new Promise((resolve, reject) => {
    sportCategoryService.getSportCategories(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [SHOW_SPORTCATEGORY_BY_SLUG]: (context, params) => new Promise((resolve, reject) => {
    sportCategoryService.showSportCategoryBySlug(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [ADD_SPORT_CATEGORY]: (context, sportCategoryData) => new Promise((resolve, reject) => {
    sportCategoryService.addSportCategory(sportCategoryData)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [DELETE_SPORT_CATEGORY]: (context, sportCategory) => new Promise((resolve, reject) => {
    sportCategoryService.deleteSportCategory(sportCategory)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [EDIT_SPORTCATEGORY]: (context, sportCategory) => new Promise((resolve, reject) => {
    sportCategoryService.editSportCategory(sportCategory)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [UPDATE_SPORTCATEGORY]: (context, sportCategory) => new Promise((resolve, reject) => {
    sportCategoryService.updateSportCategory(sportCategory)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
