import FacilityDetailOverview
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-overview/FacilityDetailOverview.vue'
import FacilityDetailEmployees
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-employees/FacilityDetailEmployees.vue'
import FacilityDetailFacilities
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-facilities/FacilityDetailFacilities.vue'
import FacilityDetailCourseDates
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-course-dates/FacilityDetailCourseDates.vue'
import FacilityDetailEvents
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-events/FacilityDetailEvents.vue'
import FacilityDetailSettings
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-settings/FacilityDetailSettings.vue'
import FacilityDetailCoachings
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-coachings/FacilityDetailCoachings.vue'
import FacilityDetailCourses
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-courses/FacilityDetailCourses.vue'
import FacilityDetailFeedbacks
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-feedbacks/FacilityDetailFeedbacks.vue'
import FacilityDetailReviews
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-reviews/FacilityDetailReviews.vue'
import FacilityDetailPolls
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-polls/FacilityDetailPolls.vue'
import FacilityDetailPageParticipations
  from '@/modules/facility/views/facility-detail-page/views/facility-detail-page-participations/FacilityDetailPageParticipations.vue'

export default [
  {
    path: '',
    name: 'facility.detail.overview',
    component: FacilityDetailOverview,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'participations',
    name: 'facility.detail.participations',
    component: FacilityDetailPageParticipations,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Participations',
          to: '/facilities/participations',
          active: true,
        },
      ],
    },
  },
  {
    path: 'employees',
    name: 'facility.detail.employees',
    component: FacilityDetailEmployees,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Employees',
          to: '/facilities/employees',
          active: true,
        },
      ],
    },
  },
  {
    path: 'coachings',
    name: 'facility.detail.coachings',
    component: FacilityDetailCoachings,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facility',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Employees',
          to: '/facility/coachings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'facilities',
    name: 'facility.detail.facilities',
    component: FacilityDetailFacilities,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Facilities',
          to: '/facilities/facilities',
          active: true,
        },
      ],
    },
  },
  {
    path: 'courses',
    name: 'facility.detail.courses',
    component: FacilityDetailCourses,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Courses',
          to: '/facilities/courses',
          active: true,
        },
      ],
    },
  },
  {
    path: 'course-dates',
    name: 'facility.detail.course-dates',
    component: FacilityDetailCourseDates,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Courses',
          to: '/facilities/course-dates',
          active: true,
        },
      ],
    },
  },
  {
    path: 'events',
    name: 'facility.detail.events',
    component: FacilityDetailEvents,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Events',
          to: '/facilities/events',
          active: true,
        },
      ],
    },
  },
  {
    path: 'feedbacks',
    name: 'facility.detail.feedbacks',
    component: FacilityDetailFeedbacks,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Feedbacks',
          to: '/facilities/feedbacks',
          active: true,
        },
      ],
    },
  },
  {
    path: 'reviews',
    name: 'facility.detail.reviews',
    component: FacilityDetailReviews,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Reviews',
          to: '/facilities/reviews',
          active: true,
        },
      ],
    },
  },
  {
    path: 'polls',
    name: 'facility.detail.polls',
    component: FacilityDetailPolls,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Polls',
          to: '/facilities/polls',
          active: true,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'facility.detail.settings',
    component: FacilityDetailSettings,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilitys',
          to: '/facilities',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Settings',
          to: '/facilities/settings',
          active: true,
        },
      ],
    },
  },
]
