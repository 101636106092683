import FacilitiesPage from '@/modules/facility/views/facilities-page/FacilitiesPage.vue'
import FacilityDetailPage from '@/modules/facility/views/facility-detail-page/FacilityDetailPage.vue'
import FacilityDetailRoutes from '@/modules/facility/views/facility-detail-page/router'

export default [
  {
    path: '/facilities',
    component: FacilitiesPage,
    name: 'facility.list',
    meta: {
      pageTitle: 'Facilities',
      breadcrumb: [
        {
          text: 'Facilities',
          to: '/facilities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/facilities/:slug',
    name: 'facility.detail',
    redirect: { name: 'facility.detail.overview' },
    component: FacilityDetailPage,
    meta: {
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facilities',
          to: '/facilities',
          active: false,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
    children: [
      ...FacilityDetailRoutes,
    ],
  },
]
