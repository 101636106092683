<template>
  <div>
    <user-detail-header
      v-if="user"
      :user-data="user"
    />
    <div v-else>
      Loading...
    </div>
    <router-view />
  </div>
</template>

<script>
import { FETCH_USER } from '@/modules/user/store/action-types'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import UserDetailHeader from './components/UserDetailHeader.vue'

export default {
  components: { UserDetailHeader },
  setup() {
    const { route } = useRouter()
    const user = ref(null)

    const loadUser = async () => {
      store.dispatch(FETCH_USER, route.value.params.username)
        .then(res => {
          user.value = res.data.data
        })
    }

    loadUser()

    return {
      user,
    }
  },
}
</script>

<style scoped>

</style>
