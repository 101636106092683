<template>
  <b-card no-body>
    <b-card-header class="flex-column align-items-start">
      <b-card-title>Create Organization</b-card-title>
      <b-card-text class="text-muted mt-25">
        Type in the information about the organization
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <validation-observer
        ref="refFormObserver"
        #default="{invalid,handleSubmit}"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col>
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="name"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="organizationData.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="About"
                label-for="about"
              >
                <b-form-input
                  id="about"
                  v-model="organizationData.about"
                  placeholder="About"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="organizationData.email"
                    :state="errors.length > 0 ? false:null"
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- email -->
              <b-form-group
                label="Admin user email"
                label-for="email-admin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email-admin"
                  rules="required|email"
                >
                  <b-form-input
                    id="email-admin"
                    v-model="organizationData.email_admin"
                    :state="errors.length > 0 ? false:null"
                    type="email"
                    placeholder="Admin user email"
                    name="email-admin"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Phone"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="phone"
                  name="Phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="organizationData.phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Phone"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Website"
                label-for="website"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="website"
                  name="Website"
                >
                  <b-form-input
                    id="website"
                    v-model="organizationData.website"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Website"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Postal Code"
                label-for="postalCode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postal code"
                  rules="required"
                >
                  <b-form-input
                    id="postalCode"
                    v-model="organizationData.postal_code"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Postal code"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="City"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <b-form-input
                    id="city"
                    v-model="organizationData.city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="City"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Address"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="organizationData.address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Country"
                label-for="country"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-input
                    id="country"
                    v-model="organizationData.country"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Country"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- password -->
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model="organizationData.password"
                      type="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      placeholder="············"
                      autocomplete="on"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <!-- password confirm -->
              <b-form-group
                label="Password confirm"
                label-for="new-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="New password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="new-password"
                      v-model="organizationData.c_password"
                      type="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      placeholder="············"
                      autocomplete="on"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Status"
                label-for="status"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="status"
                  name="Status"
                  rules="required"
                >
                  <v-select
                    v-model="organizationData.status"
                    :state="errors.length > 0 ? false:null"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    placeholder="Status"
                    label="value"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="invalid || loading"
                @click="onSubmit"
              >
                <b-spinner
                  v-if="loading"
                  small
                />
                Add Organization
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BSpinner, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { CREATE_ORGANIZATION, STORE_ORGANIZATION } from '@/modules/organization/store/action-types'
// eslint-disable-next-line import/no-cycle
// import axios from '@/libs/axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return
        this.loading = true
        this.$store.dispatch(STORE_ORGANIZATION, this.organizationData)
          .then(() => {
            this.$router.push({ name: 'organization.list' })
          })
          .catch(error => {
            this.$refs.refFormObserver.setErrors(error.response.data.errors)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Add Organization Failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
  setup() {
    const fillTestData = true

    const organizationData = ref({
      name: fillTestData ? 'Organization #1' : null,
      about: null,
      email: fillTestData ? 'organization1@besportyapp.com' : null,
      email_admin: fillTestData ? 'markus@besportyapp.com' : null,
      phone: null,
      website: null,
      postal_code: fillTestData ? '49179' : null,
      city: fillTestData ? 'Ostercappeln' : null,
      address: fillTestData ? 'Von-Bodelschwingh-Str. 18' : null,
      country: fillTestData ? 'Deutschland' : null,
      password: fillTestData ? 'password' : null,
      c_password: fillTestData ? 'password' : null,
      status: 'confirmed',
    })

    const loading = ref(false)

    const statusOptions = ref([])

    const loadCreate = async () => {
      store.dispatch(CREATE_ORGANIZATION)
        .then(response => {
          statusOptions.value = response.data.status
        })
    }

    loadCreate()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      organizationData,
      loading,
      statusOptions,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      required,
      integer,

    }
  },
}
</script>

<style scoped>

</style>
