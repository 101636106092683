import { useToast } from 'vue-toastification/composition'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import {
  FETCH_ESTABLISHMENTS,
  REMOVE_ESTABLISHMENT,
  UPDATE_ESTABLISHMENT,
  FETCH_ESTABLISHMENTS_FILTERS,
} from '@/modules/establishment/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEstablishments() {
  const toast = useToast()

  const refEstablishmentListTable = ref(null)

  const tableColumns = [
    { key: 'place', sortable: true },
    { key: 'organization', sortable: true },
    { key: 'status_label', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(25)
  const totalEstablishments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  // Filter
  const statusOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Deactivated', value: 'deactivated' },
  ]
  const statusFilter = ref(null)
  const organizationsFilter = ref(null)
  const organizationsOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refEstablishmentListTable.value ? refEstablishmentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEstablishments.value,
    }
  })

  const refetchData = () => {
    refEstablishmentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, organizationsFilter], () => {
    refetchData()
  })

  const confirm = organization => {
    store
      .dispatch(UPDATE_ESTABLISHMENT, {
        uuid: organization.uuid,
        action: 'confirm',
      })
      .then(() => {
        refetchData()
      })
  }

  const reject = organization => {
    store
      .dispatch(UPDATE_ESTABLISHMENT, {
        uuid: organization.uuid,
        action: 'reject',
      })
      .then(() => {
        refetchData()
      })
  }

  const deleteEstablishment = establishment => {
    store
      .dispatch(REMOVE_ESTABLISHMENT, establishment.uuid)
      .then(() => {
        refetchData()
      })
  }

  const fetchEstablishments = (ctx, callback) => {
    store
      .dispatch(FETCH_ESTABLISHMENTS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        organizations: organizationsFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalEstablishments.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching establishment list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchEstablishmentsFilters = async () => {
    store.dispatch(FETCH_ESTABLISHMENTS_FILTERS)
      .then(response => {
        organizationsOptions.value = response.data.organizations
      })
  }

  fetchEstablishmentsFilters()

  return {
    fetchEstablishments,
    refetchData,

    tableColumns,
    perPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEstablishmentListTable,

    statusFilter,
    statusOptions,
    organizationsFilter,
    organizationsOptions,

    confirm,
    reject,
    deleteEstablishment,
  }
}
