import axios from 'axios'

export default {
  getProfile() {
    return axios.get('user')
  },
  updateProfile(data) {
    return axios({ url: 'profile', data, method: 'PATCH' })
  },
  changePassword(data) {
    return axios({ url: 'profile/change-password', data, method: 'POST' })
  },
}
