import UserDetailPage from '@/modules/user/views/user-detail-page/UserDetailPage.vue'
import UserEditPage from '@/modules/user/views/user-edit-page/UserEditPage.vue'
import UserDetailRoutes from '@/modules/user/views/user-detail-page/router'
import UserCreatePage from '@/modules/user/views/user-create-page/UserCreatePage.vue'
import UsersPage from '../views/users-page/UsersPage.vue'

export default [
  {
    path: '/users/',
    name: 'user.list',
    component: UsersPage,
    meta: {
      pageTitle: 'User list',
      breadcrumb: [
        {
          text: 'User list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/create',
    name: 'user.create',
    component: UserCreatePage,
    meta: {
      pageTitle: 'Create User',
      breadcrumb: [
        {
          text: 'User list',
          to: '/users',
        },
        {
          text: 'Create User',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:username',
    name: 'user.detail',
    redirect: { name: 'user.detail.overview' },
    component: UserDetailPage,
    meta: {
      pageTitle: 'User Show',
      breadcrumb: [
        {
          text: 'User List',
          to: '/users',
        },
        {
          text: 'User Show',
          active: true,
        },
      ],
    },
    children: [
      ...UserDetailRoutes,
    ],
  },
  {
    path: '/users:username/edit',
    name: 'users.edit',
    component: UserEditPage,
    meta: {
      pageTitle: 'User Edit',
      breadcrumb: [
        {
          text: 'User List',
        },
        {
          text: 'User Edit',
          active: true,
        },
      ],
    },
  },
]
