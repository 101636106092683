<template>
  <div>
    <router-view />
    <!-- <router-view
      v-if="moduleIsReady"
      :key="moduleKey"
    />
    <div v-else>
      Loading Setting Module...
    </div> -->
  </div>
</template>

<script>

// import { SETTINGS_FETCH } from './store/action-types'

export default {

  name: 'SettingModule',

  computed: {
    // setting() {
    //   return this.$store.getters.setting
    // },
    moduleIsReady() {
      return this.setting
    },
    moduleKey() {
      return 0
    },
  },

  created() {
    // this.bootstrap()
  },

  methods: {
    // async bootstrap() {
    //   // const id = +this.$route.params.id;
    //   await this.$store.dispatch(SETTINGS_FETCH)
    // },
  },

}
</script>
