<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Postal Code"
            label-for="postal_code"
          >
            <b-form-input
              v-model="organizationData.postal_code"
              placeholder="Postal Code"
              name="postal_code"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              v-model="organizationData.city"
              placeholder="City"
              name="city"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Address"
            label-for="address"
          >
            <b-form-input
              v-model="organizationData.address"
              placeholder="Address"
              name="address"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              v-model="organizationData.country"
              placeholder="Country"
              name="country"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resetForm() {
    },
  },
}
</script>
