import { FETCH_COURSES, FETCH_COURSES_FILTERS } from '@/modules/course/store/action-types'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCourses() {
  const toast = useToast()

  const refCourseListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'organization.name', sortable: true },
    { key: 'sportCategory.name', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalCourses = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)
  const sportCategoriesFilter = ref(null)
  const sportCategoriesOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourses.value,
    }
  })

  const refetchData = () => {
    refCourseListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, sportCategoriesFilter], () => {
    refetchData()
  })

  const fetchCourses = (ctx, callback) => {
    store
      .dispatch(FETCH_COURSES, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        sportCategories: sportCategoriesFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalCourses.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching course date list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchCoursesFilters = async () => {
    store.dispatch(FETCH_COURSES_FILTERS)
      .then(response => {
        sportCategoriesOptions.value = response.data.sportCategories
      })
  }

  fetchCoursesFilters()

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Filter
  const statusOptions = [
    { label: 'Draft', value: 'draft' },
    { label: 'Pending', value: 'pending' },
    { label: 'Published', value: 'published' },
  ]

  return {
    tableColumns,
    perPage,
    currentPage,
    totalCourses,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,

    fetchCourses,
    refetchData,

    statusFilter,
    statusOptions,
    sportCategoriesFilter,
    sportCategoriesOptions,
  }
}
