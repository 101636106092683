<template>
  <b-tabs
    class="ml-1"
    pills
  >
    <template #tabs-start>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.overview'"
        :to="{ name: 'user.detail.overview'}"
      >
        <span class="d-none d-md-block">Overview</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.activities'"
        :to="{ name: 'user.detail.activities'}"
      >
        <span class="d-none d-md-block">Activities</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.absences'"
        :to="{ name: 'user.detail.absences'}"
      >
        <span class="d-none d-md-block">Absences</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.body-weights'"
        :to="{ name: 'user.detail.body-weights'}"
      >
        <span class="d-none d-md-block">Body weights</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.chats'"
        :to="{ name: 'user.detail.chats'}"
      >
        <span class="d-none d-md-block">Chats</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.checkins'"
        :to="{ name: 'user.detail.checkins'}"
      >
        <span class="d-none d-md-block">Checkins</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.coachings'"
        :to="{ name: 'user.detail.coachings'}"
      >
        <span class="d-none d-md-block">Coachings</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.comments'"
        :to="{ name: 'user.detail.comments'}"
      >
        <span class="d-none d-md-block">Comments</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.daily-routines'"
        :to="{ name: 'user.detail.daily-routines'}"
      >
        <span class="d-none d-md-block">Daily Routines</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.followers'"
        :to="{ name: 'user.detail.followers'}"
      >
        <span class="d-none d-md-block">Followers</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.followings'"
        :to="{ name: 'user.detail.followings'}"
      >
        <span class="d-none d-md-block">Followings</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.friends'"
        :to="{ name: 'user.detail.friends'}"
      >
        <span class="d-none d-md-block">Friends</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.hostings'"
        :to="{ name: 'user.detail.hostings'}"
      >
        <span class="d-none d-md-block">Hostings</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.meals'"
        :to="{ name: 'user.detail.meals'}"
      >
        <span class="d-none d-md-block">Meals</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.organizations'"
        :to="{ name: 'user.detail.organizations'}"
      >
        <span class="d-none d-md-block">Organizations</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.establishments'"
        :to="{ name: 'user.detail.establishments'}"
      >
        <span class="d-none d-md-block">Establishments</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.participations'"
        :to="{ name: 'user.detail.participations'}"
      >
        <span class="d-none d-md-block">Participations</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.substitute-requests'"
        :to="{ name: 'user.detail.substitute-requests'}"
      >
        <span class="d-none d-md-block">Substitute Requests</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.settings'"
        :to="{ name: 'user.detail.settings'}"
      >
        <span class="d-none d-md-block">Settings</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='user.detail.teams'"
        :to="{ name: 'user.detail.teams'}"
      >
        <span class="d-none d-md-block">Teams</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
import { BNavItem, BTabs } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BNavItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
