import { computed, ref, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FETCH_ORGANIZATION_REVIEWS } from '@/modules/organization/store/action-types'

export default function useOrganizationReviews() {
  const toast = useToast()

  const refReviewListTable = ref(null)

  const tableColumns = [
    { key: 'course.name', sortable: true },
    { key: 'start_time', sortable: true },
    { key: 'establishment', sortable: true },
    { key: 'course.sportCategory.name', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalReviews = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)
  const timeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refReviewListTable.value ? refReviewListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReviews.value,
    }
  })

  const refetchData = () => {
    refReviewListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, timeFilter], () => {
    refetchData()
  })

  const fetchReviews = async (ctx, callback) => {
    store
      .dispatch(FETCH_ORGANIZATION_REVIEWS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        time: timeFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalReviews.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching organization review list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    perPage,
    currentPage,
    totalReviews,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReviewListTable,

    fetchReviews,
    refetchData,

    statusFilter,
    timeFilter,
  }
}
