<template>
  <div>
    <router-view
      v-if="moduleIsReady"
      :key="moduleKey"
    />
    <div v-else>
      Loading Sport categories Module...
    </div>
  </div>
</template>

<script>

import { FETCH_SPORT_CATEGORIES } from './store/action-types'

export default {

  name: 'SportCategoryModule',

  computed: {
    // sportCategories() {
    //   return this.$store.getters.sportCategories
    // },
    moduleIsReady() {
      return true // this.sportCategories
    },
    moduleKey() {
      return 0
    },
  },

  created() {
    // this.bootstrap()
  },

  methods: {
    async bootstrap() {
      await this.$store.dispatch(FETCH_SPORT_CATEGORIES)
    },
  },

}
</script>
