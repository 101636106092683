import { render, staticRenderFns } from "./FacilityDetailEvents.vue?vue&type=template&id=581128c8&scoped=true&"
import script from "./FacilityDetailEvents.vue?vue&type=script&lang=js&"
export * from "./FacilityDetailEvents.vue?vue&type=script&lang=js&"
import style0 from "./FacilityDetailEvents.vue?vue&type=style&index=0&id=581128c8&lang=scss&scoped=true&"
import style1 from "./FacilityDetailEvents.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581128c8",
  null
  
)

export default component.exports