import { render, staticRenderFns } from "./EventDetailSubstituteRequestsPage.vue?vue&type=template&id=046167dc&scoped=true&"
import script from "./EventDetailSubstituteRequestsPage.vue?vue&type=script&lang=js&"
export * from "./EventDetailSubstituteRequestsPage.vue?vue&type=script&lang=js&"
import style0 from "./EventDetailSubstituteRequestsPage.vue?vue&type=style&index=0&id=046167dc&lang=scss&scoped=true&"
import style1 from "./EventDetailSubstituteRequestsPage.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "046167dc",
  null
  
)

export default component.exports