<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <OrganizationCreateForm />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import OrganizationCreateForm
  from '@/modules/organization/views/organization-create-page/components/OrganizationCreateForm.vue'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    OrganizationCreateForm,
    BRow,
    BCol,
  },
}
</script>
