import { render, staticRenderFns } from "./EventDetailInvitationsPage.vue?vue&type=template&id=5db578ab&scoped=true&"
import script from "./EventDetailInvitationsPage.vue?vue&type=script&lang=js&"
export * from "./EventDetailInvitationsPage.vue?vue&type=script&lang=js&"
import style0 from "./EventDetailInvitationsPage.vue?vue&type=style&index=0&id=5db578ab&lang=scss&scoped=true&"
import style1 from "./EventDetailInvitationsPage.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db578ab",
  null
  
)

export default component.exports