import { render, staticRenderFns } from "./UserDetailBodyWeightsPage.vue?vue&type=template&id=43e12cfa&scoped=true&"
import script from "./UserDetailBodyWeightsPage.vue?vue&type=script&lang=js&"
export * from "./UserDetailBodyWeightsPage.vue?vue&type=script&lang=js&"
import style0 from "./UserDetailBodyWeightsPage.vue?vue&type=style&index=0&id=43e12cfa&lang=scss&scoped=true&"
import style1 from "./UserDetailBodyWeightsPage.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43e12cfa",
  null
  
)

export default component.exports