<template>
  <b-card no-body>
    <b-card-header class="flex-column align-items-start">
      <b-card-title>Create User</b-card-title>
      <b-card-text class="text-muted mt-25">
        Type in the information about the user
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <validation-observer
        ref="refFormObserver"
        #default="{invalid,handleSubmit}"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>

            <b-col>
              <b-form-group
                label="Username"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="username"
                  name="Username"
                  rules="required"
                  immediate
                >
                  <b-form-input
                    id="username"
                    v-model="userData.username"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="username"
                    placeholder="Username"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Firstname"
                label-for="first_name"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="first_name"
                  name="Firstname"
                  rules=""
                  immediate
                >
                  <b-form-input
                    id="first_name"
                    v-model="userData.first_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Firstname"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Lastname"
                label-for="last_name"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="last_name"
                  name="Lastname"
                  rules=""
                  immediate
                >
                  <b-form-input
                    id="last_name"
                    v-model="userData.last_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Lastname"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="email"
                  name="Email"
                  rules="required|email"
                  immediate
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|password"
                  immediate
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="userData.password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      autocomplete="new-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Confirm Password"
                label-for="password_confirm"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="c_password"
                  name="Confirm Password"
                  rules="required|confirmed:password"
                  immediate
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password_confirm"
                      v-model="userData.password_confirm"
                      :type="password2FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="confirm-password"
                      autocomplete="new-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="invalid || loading"
            @click="onSubmit"
          >
            <b-spinner
              v-if="loading"
              small
            />
            Create
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BSpinner,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { computed, ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { USER_ADD } from '@/modules/user/store/action-types'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
  },
  setup(props, { refs }) {
    const toast = useToast()
    const isDev = computed(() => process.env.VUE_APP_MODE === 'development')
    const loading = ref(false)

    const password1FieldType = ref('password')
    const password2FieldType = ref('password')

    const password1ToggleIcon = computed(() => (password1FieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const password2ToggleIcon = computed(() => (password2FieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))

    const userData = ref({
      first_name: isDev.value ? 'Test' : null,
      last_name: isDev.value ? 'User' : null,
      username: isDev.value ? 'testuser1' : null,
      email: isDev.value ? 'testuser1@besportyapp.com' : null,
      password: isDev.value ? 'Password$1' : null,
      password_confirm: isDev.value ? 'Password$1' : null,
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    const togglePassword1Visibility = () => {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    }
    const togglePassword2Visibility = () => {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    }

    const onSubmit = async () => {
      refs.refFormObserver.validate().then(success => {
        if (!success) return
        loading.value = true
        store.dispatch(USER_ADD, userData.value)
          .then(() => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Create User successfully',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Create User failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            refs.refFormObserver.setErrors(error.response.data.errors)
          })
          .finally(() => {
            loading.value = false
          })
      })
    }

    return {
      password1FieldType,
      password2FieldType,
      password1ToggleIcon,
      password2ToggleIcon,
      loading,
      userData,
      onSubmit,
      togglePassword1Visibility,
      togglePassword2Visibility,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style scoped>

</style>
