<template>
  <b-card
    class="mb-0"
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          cols="12"
          md="6"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :clearable="false"
            :options="perPageOptions"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-button
              class="ml-1"
              variant="primary"
              @click="refetchData"
            >
              <span class="text-nowrap">Reload</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refFaqListTable"
      class="position-relative"
      :items="fetchFaqs"
      responsive
      :fields="tableColumns"
      primary-key="uuid"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${resolveFaqStatusVariant(data.item.status_label)}`"
          class="text-capitalize"
          pill
        >
          {{ data.item.status_label }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div>
          <!-- Dropdown -->
          <b-dropdown
            no-caret
            variant="link"
          >

            <template #button-content>
              <feather-icon
                class="align-middle text-body"
                icon="MoreVerticalIcon"
                size="16"
              />
            </template>
            <b-dropdown-item @click="delete(data.item)">
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalFaqs"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BBadge, BButton,
  BCard, BCol, BDropdown, BDropdownItem, BFormInput, BPagination, BRow,
  BTable,
} from 'bootstrap-vue'
import { FAQS_FETCH } from '@/modules/faq/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import { computed, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  name: 'FaqsPage',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  setup() {
    const toast = useToast()

    const refFaqListTable = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'question', sortable: false },
      { key: 'answer', sortable: false },
      { key: 'status', sortable: true },
      { key: 'actions' },
    ]

    const perPage = ref(10)
    const totalFaqs = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('status')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
      const localItemsCount = refFaqListTable.value ? refFaqListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalFaqs.value,
      }
    })

    const refetchData = () => {
      refFaqListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })

    const fetchFaqs = (ctx, callback) => {
      store.dispatch(FAQS_FETCH, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
        .then(response => {
          const { data, meta } = response.data

          callback(data)
          // dataMeta.value = meta
          totalFaqs.value = meta.total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching faqs list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const resolveFaqStatusVariant = status => {
      if (status === 'published') return 'success'
      if (status === 'pending') return 'warning'
      return 'danger'
    }

    return {
      fetchFaqs,
      tableColumns,
      perPage,
      currentPage,
      totalFaqs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFaqListTable,

      refetchData,

      resolveFaqStatusVariant,
    }
  },
}
</script>
