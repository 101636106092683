import Vue from 'vue'

// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

import { ToastPlugin } from 'bootstrap-vue'

Vue.use(ToastPlugin)

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Authorization = localStorage.getItem('token')

axios.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('expiresAt')
    router.push({ name: 'login' })
  } else if (error.response.status === 403) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('expiresAt')
    router.push({ name: 'login' })
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns
export default axiosIns
