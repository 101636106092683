<template>
  <b-card>
    <b-card-header>
      <b-card-title>Users</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col>
          <div>
            All:{{ userData.allCount }}<br>
            Active:{{ userData.activeCount }}<br>
            Inactive:{{ userData.inactiveCount }}<br>
            Male:{{ userData.maleCount }}<br>
            Female:{{ userData.femaleCount }}
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>
