<template>
  <!-- form -->
  <validation-observer
    v-slot="{ handleSubmit, reset, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="reset"
    >
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="Old Password"
            label-for="old-password"
          >
            <validation-provider
              v-slot="{ errors }"
              vid="old_password"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="current-password"
                  placeholder="Old Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="password"
            label="New Password"
          >
            <validation-provider
              v-slot="{ errors }"
              vid="password"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="newPasswordValue"
                  :type="passwordFieldTypeNew"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="password"
                  autocomplete="new-password"
                  placeholder="New Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Retype New Password"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Confirm new Password"
              rules="required|confirmed:password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="account-retype-new-password"
                  v-model="RetypePassword"
                  :type="passwordFieldTypeRetype"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="retype-password"
                  autocomplete="new-password"
                  placeholder="Confirm new Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :disabled="invalid || loading"
            @click="onUpdate"
          >
            <b-spinner
              v-if="loading"
              small
            />
            Save changes
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BInputGroup, BInputGroupAppend,
  BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { PROFILE_CHANGE_PASSWORD } from '@/modules/profile/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',

      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      loading: false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    onUpdate() {
      const data = {
        old_password: this.passwordValueOld,
        password: this.newPasswordValue,
      }

      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return
        this.loading = true
        store.dispatch(PROFILE_CHANGE_PASSWORD, data)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Update Password successfully',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Update Password Failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.$refs.refFormObserver.setErrors(error.response.data.errors)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
