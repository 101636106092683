import {
  ROLES_PERMISSIONS_FETCH,
  ROLES_PERMISSIONS_FETCH_SUCCESS,
} from './action-types'
import rolePermissionService from '../services'

export default {
  [ROLES_PERMISSIONS_FETCH]: ({ commit }, params) => new Promise((resolve, reject) => {
    rolePermissionService.getRolesPermissions(params)
      .then(response => {
        const rolesPermissions = response.data
        commit(ROLES_PERMISSIONS_FETCH_SUCCESS, rolesPermissions)
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
