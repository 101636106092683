import { SET_ESTABLISHMENT_DETAIL_SLUG } from '@/modules/establishment/store/mutation-types'
import establishmentService from '../services'
import {
  FETCH_ESTABLISHMENT, FETCH_ESTABLISHMENT_COACHINGS,
  FETCH_ESTABLISHMENT_COURSE_DATES, FETCH_ESTABLISHMENT_COURSES,
  FETCH_ESTABLISHMENT_EMPLOYEES,
  FETCH_ESTABLISHMENT_EVENTS,
  FETCH_ESTABLISHMENT_FACILITIES, FETCH_ESTABLISHMENT_FEEDBACKS, FETCH_ESTABLISHMENT_POLLS, FETCH_ESTABLISHMENT_REVIEWS,
  FETCH_ESTABLISHMENTS,
  FETCH_ESTABLISHMENTS_FILTERS,
  REMOVE_ESTABLISHMENT,
  STORE_ESTABLISHMENT,
  UPDATE_ESTABLISHMENT,
} from './action-types'

export default {
  [FETCH_ESTABLISHMENTS]: (context, params) => new Promise((resolve, reject) => {
    establishmentService.getEstablishments(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT]: (context, slug) => new Promise((resolve, reject) => {
    context.commit(SET_ESTABLISHMENT_DETAIL_SLUG, slug)
    establishmentService.getEstablishment(slug)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_COURSE_DATES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getCourseDates({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_REVIEWS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getReviews({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_POLLS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getPolls({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_FEEDBACKS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getFeedbacks({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_EMPLOYEES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getEmployees({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_FACILITIES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getFacilities({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_EVENTS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getEvents({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_COACHINGS]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getCoachings({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENT_COURSES]: (context, params) => new Promise((resolve, reject) => {
    const slug = context.getters.getEstablishmentDetailSlug
    establishmentService.getCourses({ slug, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [STORE_ESTABLISHMENT]: (context, data) => new Promise((resolve, reject) => {
    establishmentService.storeEstablishment(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [UPDATE_ESTABLISHMENT]: (context, establishment) => new Promise((resolve, reject) => {
    establishmentService.updateEstablishment(establishment)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [REMOVE_ESTABLISHMENT]: (context, uuid) => new Promise((resolve, reject) => {
    establishmentService.deleteEstablishment(uuid)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_ESTABLISHMENTS_FILTERS]: () => new Promise((resolve, reject) => {
    establishmentService.getEstablishmentsFilters()
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
