import {
  USER_ADD,
  USERS_FETCH,
  UPDATE_USER,
  FETCH_USER,
  USER_DELETE,
  FETCH_USER_PARTICIPATIONS,
  FETCH_USER_COACHINGS,
  FETCH_USER_ORGANIZATIONS,
  FETCH_USER_TEAMS,
  FETCH_USER_BODY_WEIGHTS,
  FETCH_USER_FRIENDS,
  FETCH_USER_FOLLOWERS,
  FETCH_USER_FOLLOWINGS,
  FETCH_USER_HOSTINGS,
  FETCH_USER_SOCIAL_LOGINS,
  FETCH_USER_ABSENCES,
  FETCH_USER_CHECKINS,
  FETCH_USER_COMMENTS,
  FETCH_USER_SUBSTITUTE_REQUESTS, USER_INVITE,
} from '@/modules/user/store/action-types'

// eslint-disable-next-line import/named
import { SET_USERNAME } from '@/modules/user/store/mutation-types'
import userService from '../services'

export default {
  [USERS_FETCH]: (context, params) => new Promise((resolve, reject) => {
    userService.getUsers(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER]: (context, username) => new Promise((resolve, reject) => {
    context.commit(SET_USERNAME, username)
    userService.getUserByUsername(username)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_PARTICIPATIONS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getParticipationsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_SUBSTITUTE_REQUESTS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getSubstituteRequestsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_COACHINGS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getCoachingsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_COMMENTS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getCommentsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_CHECKINS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getCheckinsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_ORGANIZATIONS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getOrganizationsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_TEAMS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getTeamsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_BODY_WEIGHTS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getBodyWeightsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_FRIENDS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getFriendsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_FOLLOWERS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getFollowersByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_FOLLOWINGS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getFollowingsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_HOSTINGS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getHostingsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_SOCIAL_LOGINS]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getSocialLoginsByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [FETCH_USER_ABSENCES]: (context, params) => new Promise((resolve, reject) => {
    const username = context.getters.getUsername
    userService.getAbsencesByUsername({ username, params })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [UPDATE_USER]: (context, data) => new Promise((resolve, reject) => {
    userService.updateUser(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [USER_ADD]: (context, userData) => new Promise((resolve, reject) => {
    userService.storeUser(userData)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [USER_INVITE]: (context, userData) => new Promise((resolve, reject) => {
    userService.inviteUser(userData)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
  [USER_DELETE]: (context, userData) => new Promise((resolve, reject) => {
    userService.deleteUser(userData)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
