<template>
  <b-tabs
    class="ml-1"
    pills
  >
    <template #tabs-start>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.overview'"
        :to="{ name: 'course-date.detail.overview'}"
      >
        <span class="d-none d-md-block">Overview</span>
        <feather-icon
          icon="RssIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.coachings'"
        :to="{ name: 'course-date.detail.coachings'}"
      >
        <span class="d-none d-md-block">Coachings</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.participations'"
        :to="{ name: 'course-date.detail.participations'}"
      >
        <span class="d-none d-md-block">Participations</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.invitations'"
        :to="{ name: 'course-date.detail.invitations'}"
      >
        <span class="d-none d-md-block">Invitations</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.hosters'"
        :to="{ name: 'course-date.detail.hosters'}"
      >
        <span class="d-none d-md-block">Hosters</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.followers'"
        :to="{ name: 'course-date.detail.followers'}"
      >
        <span class="d-none d-md-block">Followers</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.substitute-requests'"
        :to="{ name: 'course-date.detail.substitute-requests'}"
      >
        <span class="d-none d-md-block">Substitute requests</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.reactions'"
        :to="{ name: 'course-date.detail.reactions'}"
      >
        <span class="d-none d-md-block">Reactions</span>
        <feather-icon
          icon="UsersIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.course'"
        :to="{ name: 'course-date.detail.course'}"
      >
        <span class="d-none d-md-block">Course</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.establishment'"
        :to="{ name: 'course-date.detail.establishment'}"
      >
        <span class="d-none d-md-block">Establishment</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.organization'"
        :to="{ name: 'course-date.detail.organization'}"
      >
        <span class="d-none d-md-block">Organization</span>
        <feather-icon
          icon="CalendarIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
      <b-nav-item
        role="presentation"
        class="font-weight-bold"
        :active="$route.name ==='course-date.detail.settings'"
        :to="{ name: 'course-date.detail.settings'}"
      >
        <span class="d-none d-md-block">Settings</span>
        <feather-icon
          icon="SettingsIcon"
          class="d-block d-md-none"
        />
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
import { BNavItem, BTabs } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BNavItem,
  },
  props: {
    courseDateData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
