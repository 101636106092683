import {
  UPDATE_EMPLOYEE,
} from '@/modules/employee/store/action-types'
import employeeService from '../services'

export default {
  [UPDATE_EMPLOYEE]: (context, { uuid, data }) => new Promise((resolve, reject) => {
    employeeService.updateEmployee({ uuid, data })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
