import { computed, ref, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FETCH_FACILITY_COURSES } from '@/modules/facility/store/action-types'

export default function useFacilityCourses() {
  const toast = useToast()

  const refCourseListTable = ref(null)

  const tableColumns = [
    { key: 'course.name', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalCourses = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourses.value,
    }
  })

  const refetchData = () => {
    refCourseListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchCourses = async (ctx, callback) => {
    store
      .dispatch(FETCH_FACILITY_COURSES, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalCourses.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching organization course list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    perPage,
    currentPage,
    totalCourses,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,

    fetchCourses,
    refetchData,

    statusFilter,
  }
}
