import {
  FETCH_INVITATIONS,
} from './action-types'
import invitationService from '../services'

export default {
  [FETCH_INVITATIONS]: (context, params) => new Promise((resolve, reject) => {
    invitationService.getInvitations(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
