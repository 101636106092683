import UserDetailOverviewPage
  from '@/modules/user/views/user-detail-page/views/user-detail-overview-page/UserDetailOverviewPage.vue'
import UserDetailSettingsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-settings-page/UserDetailSettingsPage.vue'
import UserDetailParticipationsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-participations-page/UserDetailParticipationsPage.vue'
import UserDetailCoachingsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-coachings-page/UserDetailCoachingsPage.vue'
import UserDetailOrganizationsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-organizations-page/UserDetailOrganizationsPage.vue'
import UserDetailTeamsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-teams-page/UserDetailTeamsPage.vue'
import UserDetailBodyWeightsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-body-weights-page/UserDetailBodyWeightsPage.vue'
import UserDetailFriendsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-friends-page/UserDetailFriendsPage.vue'
import UserDetailHostingsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-hostings-page/UserDetailHostingsPage.vue'
import UserDetailFollowersPage
  from '@/modules/user/views/user-detail-page/views/user-detail-followers-page/UserDetailFollowersPage.vue'
import UserDetailFollowingsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-followings-page/UserDetailFollowingsPage.vue'
import UserDetailAbsencesPage
  from '@/modules/user/views/user-detail-page/views/user-detail-absences-page/UserDetailAbsencesPage.vue'
import UserDetailCheckinsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-checkins-page/UserDetailCheckinsPage.vue'
import UserDetailCommentsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-comments-page/UserDetailCommentsPage.vue'
import UserDetailSubstituteRequestsPage
  from '@/modules/user/views/user-detail-page/views/user-detail-substitute-requests-page/UserDetailSubstituteRequestsPage.vue'

export default [
  {
    path: '',
    name: 'user.detail.overview',
    component: UserDetailOverviewPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'participations',
    name: 'user.detail.participations',
    component: UserDetailParticipationsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Participations',
          to: '/users/participations',
          active: true,
        },
      ],
    },
  },
  {
    path: 'coachings',
    name: 'user.detail.coachings',
    component: UserDetailCoachingsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Coachings',
          to: '/users/coachings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'comments',
    name: 'user.detail.comments',
    component: UserDetailCommentsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Comments',
          to: '/users/comments',
          active: true,
        },
      ],
    },
  },
  {
    path: 'organizations',
    name: 'user.detail.organizations',
    component: UserDetailOrganizationsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Organizations',
          to: '/users/organizations',
          active: true,
        },
      ],
    },
  },
  {
    path: 'teams',
    name: 'user.detail.teams',
    component: UserDetailTeamsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Teams',
          to: '/users/teams',
          active: true,
        },
      ],
    },
  },
  {
    path: 'body-weights',
    name: 'user.detail.body-weights',
    component: UserDetailBodyWeightsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Body Weights',
          to: '/users/body-weights',
          active: true,
        },
      ],
    },
  },
  {
    path: 'friends',
    name: 'user.detail.friends',
    component: UserDetailFriendsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Friends',
          to: '/users/friends',
          active: true,
        },
      ],
    },
  },
  {
    path: 'followers',
    name: 'user.detail.followers',
    component: UserDetailFollowersPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Followers',
          to: '/users/followers',
          active: true,
        },
      ],
    },
  },
  {
    path: 'followings',
    name: 'user.detail.followings',
    component: UserDetailFollowingsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Followings',
          to: '/users/followings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'hostings',
    name: 'user.detail.hostings',
    component: UserDetailHostingsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Hosters',
          to: '/users/hostings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'absences',
    name: 'user.detail.absences',
    component: UserDetailAbsencesPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Absences',
          to: '/users/absences',
          active: true,
        },
      ],
    },
  },
  {
    path: 'checkins',
    name: 'user.detail.checkins',
    component: UserDetailCheckinsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Checkins',
          to: '/users/checkins',
          active: true,
        },
      ],
    },
  },
  {
    path: 'substitute-requests',
    name: 'user.detail.substitute-requests',
    component: UserDetailSubstituteRequestsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Substitute Requests',
          to: '/users/substitute-requests',
          active: true,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'user.detail.settings',
    component: UserDetailSettingsPage,
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Settings',
          to: '/users/settings',
          active: true,
        },
      ],
    },
  },
]
