var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Old Password","label-for":"old-password"}},[_c('validation-provider',{attrs:{"vid":"old_password","name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"old-password","name":"old-password","type":_vm.passwordFieldTypeOld,"state":errors.length > 0 ? false:null,"autocomplete":"current-password","placeholder":"Old Password"},model:{value:(_vm.passwordValueOld),callback:function ($$v) {_vm.passwordValueOld=$$v},expression:"passwordValueOld"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"password","label":"New Password"}},[_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldTypeNew,"state":errors.length > 0 ? false:null,"name":"password","autocomplete":"new-password","placeholder":"New Password"},model:{value:(_vm.newPasswordValue),callback:function ($$v) {_vm.newPasswordValue=$$v},expression:"newPasswordValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"account-retype-new-password","label":"Retype New Password"}},[_c('validation-provider',{attrs:{"name":"Confirm new Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"account-retype-new-password","type":_vm.passwordFieldTypeRetype,"state":errors.length > 0 ? false:null,"name":"retype-password","autocomplete":"new-password","placeholder":"Confirm new Password"},model:{value:(_vm.RetypePassword),callback:function ($$v) {_vm.RetypePassword=$$v},expression:"RetypePassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","disabled":invalid || _vm.loading},on:{"click":_vm.onUpdate}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Save changes ")],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }