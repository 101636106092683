import {
  PROFILE_FETCH,
  PROFILE_UPDATE,
  PROFILE_UPDATE_FAILURE,
  PROFILE_SET,
  PROFILE_CHANGE_PASSWORD,
} from './action-types'
import ProfileService from '../services'

export default {
  // [PROFILE_FETCH]: ({ commit }) => {
  //   // commit(PROFILE_SET, { user: null })
  //   return new Promise((resolve, reject) => {
  //     ProfileService.getProfile()
  //       .then(response => {
  //         const user = response.data.data
  //         commit(PROFILE_SET, { user })
  //         resolve(response)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // },
  [PROFILE_FETCH]: ({ commit }) => new Promise((resolve, reject) => {
    ProfileService.getProfile()
      .then(response => {
        const user = response.data.data
        localStorage.setItem('user', JSON.stringify(user))
        commit(PROFILE_SET, { user })
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),

  [PROFILE_UPDATE]: ({ commit }, data) => new Promise((resolve, reject) => {
    ProfileService.updateProfile(data)
      .then(response => {
        const user = response.data.data
        commit(PROFILE_SET, { user })
        resolve(response)
      })
      .catch(err => {
        commit(PROFILE_UPDATE_FAILURE, { errors: err.response.data.errors })
        reject(err)
      })
  }),

  [PROFILE_CHANGE_PASSWORD]: (context, data) => new Promise((resolve, reject) => {
    ProfileService.changePassword(data)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
