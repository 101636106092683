import {
  FEEDBACKS_FETCH_REQUEST,
  FEEDBACKS_FETCH_SUCCESS,
  FEEDBACKS_FETCH_ERROR,
} from './action-types'

export default {
  [FEEDBACKS_FETCH_REQUEST]: state => {
    state.status = 'loading'
  },
  [FEEDBACKS_FETCH_SUCCESS]: (state, feedbacks) => {
    state.status = 'success'
    state.feedbacks = feedbacks
  },
  [FEEDBACKS_FETCH_ERROR]: state => {
    state.status = 'error'
  },
}
