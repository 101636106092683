import SportCategoryEditPage from '@/modules/sport-category/views/sport-category-edit-page/SportCategoryEditPage.vue'
import SportCategoryModule from '../SportCategoryModule.vue'
import SportCategoriesPage from '../views/sport-categories-page/SportCategoriesPage.vue'

export default [
  {
    path: '/sport-categories',
    component: SportCategoryModule,
    children: [
      {
        path: '/',
        name: 'sport-category.list',
        component: SportCategoriesPage,
        meta: {
          pageTitle: 'Sport category list',
          breadcrumb: [
            {
              text: 'Sport category list',
              active: true,
            },
          ],
        },
      },
      {
        path: ':slug/edit',
        name: 'sport-category.edit',
        component: SportCategoryEditPage,
        meta: {
          pageTitle: 'Sport category edit',
          breadcrumb: [
            {
              text: 'Sport category list',
            },
            {
              text: 'Sport category edit',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
