import axios from 'axios'

export default {
  cookies() {
    return axios({ url: 'sanctum/csrf-cookie', method: 'GET' })
  },
  login(data) {
    return axios({ url: 'login', data, method: 'POST' })
  },
  logout() {
    return axios({ url: 'logout', method: 'GET' })
  },
}
