import RolePermissionModule from '../RolePermissionModule.vue'
import RolesPermissionsPage from '../views/role-permission-page/RolePermissionPage.vue'

export default [
  {
    path: '/roles-permissions',
    component: RolePermissionModule,
    children: [
      {
        path: '/',
        name: 'roles-permissions',
        component: RolesPermissionsPage,
      },
    ],
  },
]
