<template>
  <div>FacilityDetailPageParticipation</div>
</template>

<script>
export default {
  name: 'FacilityDetailPageParticipation',
}
</script>

<style scoped>

</style>
