import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ORGANIZATIONS_FETCH,
  ORGANIZATION_DELETE, UPDATE_ORGANIZATION,
} from '../../../store/action-types'

export default function useOrganizations() {
  // Use toast
  const toast = useToast()

  const refOrganizationListTable = ref(null)

  const statusOptions = [
    { label: 'Requested', value: 'requested' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'rejected' },
  ]
  const statusFilter = ref(null)

  const emailVerifiedOptions = [
    { label: 'Verified', value: true },
    { label: 'Not verified', value: false },
  ]
  const emailVerifiedFilter = ref(null)

  // const verifiedOptions = [
  //   { label: 'Verified', value: true },
  //   { label: 'Not verified', value: false },
  // ]
  // const verifiedFilter = ref(null)

  const activeOptions = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 },
  ]
  const activeFilter = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    // { key: 'email', sortable: true },
    // { key: 'website', sortable: true },
    // { key: 'employees_count', sortable: true, label: 'Employees' },
    // { key: 'establishments_count', sortable: true, label: 'Establishments' },
    // { key: 'facilities_count', sortable: true, label: 'Facilities' },
    // { key: 'teams_count', sortable: true, label: 'Teams' },
    // { key: 'is_verified', sortable: true },
    { key: 'is_email_verified', sortable: true },
    { key: 'is_active', sortable: true, label: 'active' },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalOrganizations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refOrganizationListTable.value ? refOrganizationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrganizations.value,
    }
  })

  const refetchData = () => {
    refOrganizationListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, activeFilter, emailVerifiedFilter], () => {
    refetchData()
  })

  const confirm = organization => {
    store
      .dispatch(UPDATE_ORGANIZATION, {
        uuid: organization.uuid,
        action: 'confirm',
      })
      .then(() => {
        refetchData()
      })
  }

  const reject = organization => {
    store
      .dispatch(UPDATE_ORGANIZATION, {
        uuid: organization.uuid,
        action: 'reject',
      })
      .then(() => {
        refetchData()
      })
  }

  const activate = organization => {
    store
      .dispatch(UPDATE_ORGANIZATION, {
        uuid: organization.uuid,
        action: 'activate',
      })
      .then(() => {
        refetchData()
      })
  }

  const deactivate = organization => {
    store
      .dispatch(UPDATE_ORGANIZATION, {
        uuid: organization.uuid,
        action: 'deactivate',
      })
      .then(() => {
        refetchData()
      })
  }

  const fetchOrganizations = (ctx, callback) => {
    store
      .dispatch(ORGANIZATIONS_FETCH, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        isActive: activeFilter.value,
        status: statusFilter.value,
        emailVerified: emailVerifiedFilter.value,
        // verified: verifiedFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        // dataMeta.value = meta
        totalOrganizations.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching organizations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteOrganization = organization => {
    store
      .dispatch(ORGANIZATION_DELETE, organization)
      .then(() => {
        refetchData()
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOrganizationCheckbox = checkbox => {
    if (checkbox === true) return { variant: 'success', icon: 'CheckCircleIcon' }
    if (checkbox === false) return { variant: 'danger', icon: 'InfoIcon' }
    return 'primary'
  }

  // const resolveOrganizationStatusVariant = status => {
  //   if (status === 'pending') return 'warning'
  //   if (status === 'approved') return 'success'
  //   if (status === 'rejected') return 'danger'
  //   return 'primary'
  // }

  return {
    deleteOrganization,
    fetchOrganizations,

    tableColumns,
    perPage,
    currentPage,
    totalOrganizations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrganizationListTable,

    resolveOrganizationCheckbox,
    refetchData,

    confirm,
    reject,
    activate,
    deactivate,

    // Extra Filters
    statusOptions,
    emailVerifiedOptions,
    // verifiedOptions,
    activeOptions,

    statusFilter,
    activeFilter,
    emailVerifiedFilter,
    // verifiedFilter,
  }
}
