import OrganizationShowRoutes from '@/modules/organization/views/organization-show-page/router'
import OrganizationsPage from '../views/organizations-page/OrganizationsPage.vue'
import OrganizationEditPage from '../views/organization-edit-page/OrganizationEditPage.vue'
import OrganizationShowPage from '../views/organization-show-page/views/OrganizationShowPage.vue'
import OrganizationCreatePage from '../views/organization-create-page/OrganizationCreatePage.vue'

export default [
  {
    path: '/organizations',
    name: 'organization.list',
    component: OrganizationsPage,
    meta: {
      pageTitle: 'Organizations',
      breadcrumb: [
        {
          text: 'Organizations',
          to: '/organizations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organizations/create',
    name: 'organization.create',
    component: OrganizationCreatePage,
    meta: {
      pageTitle: 'Create Organization',
      breadcrumb: [
        {
          text: 'Organization List',
          to: '/organizations',
        },
        {
          text: 'Create Organization',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organizations/:slug',
    name: 'organization.show',
    redirect: { name: 'organization.detail.overview' },
    component: OrganizationShowPage,
    meta: {
      pageTitle: 'Organization Show',
      breadcrumb: [
        {
          text: 'Organization List',
          to: '/organizations',
        },
        {
          text: 'Organization Show',
          active: true,
        },
      ],
    },
    children: [
      ...OrganizationShowRoutes,
    ],
  },
  {
    path: '/organizations/:slug/edit',
    name: 'organization.edit',
    component: OrganizationEditPage,
    meta: {
      pageTitle: 'Organization Edit',
      breadcrumb: [
        {
          text: 'Organization List',
        },
        {
          text: 'Organization Edit',
          active: true,
        },
      ],
    },
  },
]
