export const FETCH_COURSE_DATES = 'FETCH_COURSE_DATES'
export const FETCH_COURSE_DATES_FILTERS = 'FETCH_COURSE_DATES_FILTERS'
export const FETCH_COURSE_DATE = 'FETCH_COURSE_DATE'
export const FETCH_COURSE_DATE_COACHINGS = 'FETCH_COURSE_DATE_COACHINGS'
export const FETCH_COURSE_DATE_PARTICIPATIONS = 'FETCH_COURSE_DATE_PARTICIPATIONS'
export const FETCH_COURSE_DATE_INVITATIONS = 'FETCH_COURSE_DATE_INVITATIONS'
export const FETCH_COURSE_DATE_HOSTERS = 'FETCH_COURSE_DATE_HOSTERS'
export const FETCH_COURSE_DATE_FOLLOWERS = 'FETCH_COURSE_DATE_FOLLOWERS'
export const FETCH_COURSE_DATE_SUBSTITUTE_REQUESTS = 'FETCH_COURSE_DATE_SUBSTITUTE_REQUESTS'
export const FETCH_COURSE_DATE_REACTIONS = 'FETCH_COURSE_DATE_REACTIONS'
