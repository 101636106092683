<template>
  <div>
    <event-detail-header
      v-if="event"
      :event-data="event"
    />
    <div v-else>
      Loading...
    </div>
    <router-view />
  </div>
</template>

<script>
import { FETCH_EVENT } from '@/modules/event/store/action-types'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import EventDetailHeader from './components/EventDetailHeader.vue'

export default {
  components: { EventDetailHeader },
  setup() {
    const { route } = useRouter()
    const event = ref(null)

    const loadEvent = async () => {
      store.dispatch(FETCH_EVENT, route.value.params.slug)
        .then(res => {
          event.value = res.data.data
        })
    }

    loadEvent()

    return {
      event,
    }
  },
}
</script>

<style scoped>

</style>
