<template>
  <div>
    <organization-detail-header
      v-if="organization"
      :organization-data="organization"
    />
    <div v-else>
      Loading...
    </div>
    <router-view />
  </div>
</template>

<script>
import { ORGANIZATION_FETCH } from '@/modules/organization/store/action-types'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import OrganizationDetailHeader
  from '@/modules/organization/views/organization-show-page/views/components/OrganizationDetailHeader.vue'
import { ref } from '@vue/composition-api'

export default {
  name: 'OrganizationShowPage',
  components: { OrganizationDetailHeader },
  setup() {
    const { route } = useRouter()
    const organization = ref(null)

    const loadOrganization = async () => {
      store.dispatch(ORGANIZATION_FETCH, route.value.params.slug)
        .then(res => {
          organization.value = res.data.data
        })
    }

    loadOrganization()
    return {
      organization,
    }
  },
}
</script>

<style scoped>

</style>
