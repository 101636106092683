import EventDetailOverview
  from '@/modules/event/views/event-detail-page/views/event-detail-page-overview/EventDetailOverview.vue'
import EventDetailSettings
  from '@/modules/event/views/event-detail-page/views/event-detail-page-settings/EventDetailSettings.vue'
import EventDetailCoachings
  from '@/modules/event/views/event-detail-page/views/event-detail-page-coachings/EventDetailCoachings.vue'
import EventDetailParticipationsPage
  from '@/modules/event/views/event-detail-page/views/event-detail-participations-page/EventDetailParticipationsPage.vue'
import EventDetailInvitationsPage
  from '@/modules/event/views/event-detail-page/views/event-detail-invitations-page/EventDetailInvitationsPage.vue'
import EventDetailHosterPage
  from '@/modules/event/views/event-detail-page/views/event-detail-hoster-page/EventDetailHosterPage.vue'
import EventDetailFollowersPage
  from '@/modules/event/views/event-detail-page/views/event-detail-followers-page/EventDetailFollowersPage.vue'
import EventDetailSubstituteRequestsPage
  from '@/modules/event/views/event-detail-page/views/event-detail-substitute-requests-page/EventDetailSubstituteRequestsPage.vue'
import EventDetailReactionsPage
  from '@/modules/event/views/event-detail-page/views/event-detail-reactions-page/EventDetailReactionsPage.vue'

export default [
  {
    path: '',
    name: 'event.detail.overview',
    component: EventDetailOverview,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/events',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: 'coachings',
    name: 'event.detail.coachings',
    component: EventDetailCoachings,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Employees',
          to: '/event/coachings',
          active: true,
        },
      ],
    },
  },
  {
    path: 'participations',
    name: 'event.detail.participations',
    component: EventDetailParticipationsPage,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Participations',
          to: '/event/participations',
          active: true,
        },
      ],
    },
  },
  {
    path: 'invitations',
    name: 'event.detail.invitations',
    component: EventDetailInvitationsPage,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Invitations',
          to: '/event/invitations',
          active: true,
        },
      ],
    },
  },
  {
    path: 'hosters',
    name: 'event.detail.hosters',
    component: EventDetailHosterPage,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Hosters',
          to: '/event/hosters',
          active: true,
        },
      ],
    },
  },
  {
    path: 'followers',
    name: 'event.detail.followers',
    component: EventDetailFollowersPage,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Followers',
          to: '/event/followers',
          active: true,
        },
      ],
    },
  },
  {
    path: 'substitute-requests',
    name: 'event.detail.substitute-requests',
    component: EventDetailSubstituteRequestsPage,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Substitute Requests',
          to: '/event/substitute-requests',
          active: true,
        },
      ],
    },
  },
  {
    path: 'reactions',
    name: 'event.detail.reactions',
    component: EventDetailReactionsPage,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Reactions',
          to: '/event/reactions',
          active: true,
        },
      ],
    },
  },
  {
    path: 'settings',
    name: 'event.detail.settings',
    component: EventDetailSettings,
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: '/events',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Settings',
          to: '/events/settings',
          active: true,
        },
      ],
    },
  },
]
