import axios from 'axios'

export default {
  getEvents(params) {
    return axios({
      url: 'events', params, data: null, method: 'GET',
    })
  },
  getEventsFilters() {
    return axios({
      url: 'events/filters', params: null, data: null, method: 'GET',
    })
  },
  getEvent(slug) {
    return axios({
      url: `events/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  getParticipations({ slug, params }) {
    return axios({
      url: `events/slug/${slug}/participations`, params, data: null, method: 'GET',
    })
  },
  getInvitations({ slug, params }) {
    return axios({
      url: `events/slug/${slug}/invitations`, params, data: null, method: 'GET',
    })
  },
  getHosters({ slug, params }) {
    return axios({
      url: `events/slug/${slug}/hosters`, params, data: null, method: 'GET',
    })
  },
  getFollowers({ slug, params }) {
    return axios({
      url: `events/slug/${slug}/followers`, params, data: null, method: 'GET',
    })
  },
  getSubstituteRequests({ slug, params }) {
    return axios({
      url: `events/slug/${slug}/substitute-requests`, params, data: null, method: 'GET',
    })
  },
  getReactions({ slug, params }) {
    return axios({
      url: `events/slug/${slug}/reactions`, params, data: null, method: 'GET',
    })
  },
  getCoachings({ slug, params }) {
    return axios({
      url: `events/slug/${slug}/coachings`, params, data: null, method: 'GET',
    })
  },
}
