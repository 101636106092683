<template>
  <div>
    <event-filters
      :time-filter.sync="timeFilter"
      :time-options="timeOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :sport-category-filter.sync="sportCategoryFilter"
      :sport-category-options="sportCategoryOptions"
    />
    <b-card
      class="mb-0"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

              <b-button
                class="ml-1"
                variant="primary"
                @click="refetchData"
              >
                <span class="text-nowrap">Reload</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refEventListTable"
        class="position-relative"
        :items="fetchEvents"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: event -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'event.detail', params: { slug: data.item.slug } }"
            class="font-weight-bold d-block text-nowrap"
          >
            <div>{{ data.item.name }}</div>
          </b-link>
        </template>

        <!--        &lt;!&ndash; Column: establishment &ndash;&gt;-->
        <!--        <template #cell(establishment)="data">-->
        <!--          <b-link-->
        <!--            v-if="data.item.establishment"-->
        <!--            :to="{ name: 'establishment.detail', params: { slug: data.item.establishment.place.slug } }"-->
        <!--            class="font-weight-bold d-block text-nowrap"-->
        <!--          >-->
        <!--            <div>{{ data.item.establishment.place.name }}</div>-->
        <!--            <small>{{ data.item.name }}</small>-->
        <!--          </b-link>-->
        <!--        </template>-->

        <!--        &lt;!&ndash; Column: facility &ndash;&gt;-->
        <!--        <template #cell(facility)="data">-->
        <!--          <div v-if="data.item.facility">-->
        <!--            <b-link-->
        <!--              :to="{ name: 'facility.detail', params: { slug: data.item.facility.slug } }"-->
        <!--              class="font-weight-bold d-block text-nowrap"-->
        <!--            >-->
        <!--              <div>{{ data.item.facility.name }}</div>-->
        <!--            </b-link>-->
        <!--          </div>-->
        <!--        </template>-->

        <template #cell(start_time)="data">
          {{ data.item.start_time | formatDate('DD-MM-YYYY hh:mm') }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${data.item.state}`"
            class="text-capitalize"
            pill
          >
            {{ data.item.status_label }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <!-- Dropdown -->
            <b-dropdown
              no-caret
              variant="link"
            >

              <template #button-content>
                <feather-icon
                  class="align-middle text-body"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item :to="{ name: 'event.detail', params: { slug: data.item.slug } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalEvents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BDropdown, BDropdownItem, BTable,
  BFormInput, BButton, BPagination, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
// import store from '@/store'
import EventFilters from '@/modules/event/views/events-page/components/EventFilters.vue'
// eslint-disable-next-line import/no-cycle
import useEvents from './useEvents'

// import courseDateStoreModule from '../../store'

export default {
  name: 'EventsPage',
  components: {
    EventFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BLink,

    vSelect,
  },
  setup() {
    // const COURSE_DATE_STORE_MODULE_NAME = 'courseDate'
    //
    // // Register module
    // if (!store.hasModule(COURSE_DATE_STORE_MODULE_NAME)) store.registerModule(COURSE_DATE_STORE_MODULE_NAME, courseDateStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(COURSE_DATE_STORE_MODULE_NAME)) store.unregisterModule(COURSE_DATE_STORE_MODULE_NAME)
    // })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEventListTable,

      fetchEvents,
      refetchData,

      timeOptions,
      timeFilter,
      statusOptions,
      statusFilter,
      sportCategoryOptions,
      sportCategoryFilter,
    } = useEvents()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEventListTable,

      fetchEvents,
      refetchData,

      timeOptions,
      timeFilter,
      statusOptions,
      statusFilter,
      sportCategoryOptions,
      sportCategoryFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
