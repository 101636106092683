import axios from 'axios'

export default {
  getEstablishments(params) {
    return axios({
      url: 'establishments', params, data: null, method: 'GET',
    })
  },
  getEstablishment(slug) {
    return axios({
      url: `establishments/slug/${slug}`, params: null, data: null, method: 'GET',
    })
  },
  getCourseDates({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/course-dates`, params, data: null, method: 'GET',
    })
  },
  getReviews({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/reviews`, params, data: null, method: 'GET',
    })
  },
  getPolls({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/polls`, params, data: null, method: 'GET',
    })
  },
  getFeedbacks({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/feedbacks`, params, data: null, method: 'GET',
    })
  },
  getEmployees({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/employees`, params, data: null, method: 'GET',
    })
  },
  getFacilities({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/facilities`, params, data: null, method: 'GET',
    })
  },
  getEvents({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/events`, params, data: null, method: 'GET',
    })
  },
  getCoachings({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/coachings`, params, data: null, method: 'GET',
    })
  },
  getCourses({ slug, params }) {
    return axios({
      url: `establishments/slug/${slug}/courses`, params, data: null, method: 'GET',
    })
  },
  updateEstablishment(establishment) {
    return axios({
      url: `establishments/${establishment.uuid}`, data: establishment, method: 'PATCH',
    })
  },
  deleteEstablishment(uuid) {
    return axios({ url: `establishments/${uuid}`, data: null, method: 'DELETE' })
  },
  getEstablishmentsFilters() {
    return axios({
      url: 'establishments/filters', params: null, data: null, method: 'GET',
    })
  },
}
