<template>

  <div>
    <b-row>
      <b-col>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="email"
                  class="mr-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="employeeEmail"
                      :state="errors.length > 0 ? false:null"
                      type="email"
                      placeholder="Email"
                      name="email"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                >
                  <b-spinner
                    v-if="loading"
                    small
                  />
                  Invite
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol,
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { INVITE_EMPLOYEE } from '@/modules/organization/store/action-types'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return
        this.loading = true
        this.$store.dispatch(INVITE_EMPLOYEE, { email: this.employeeEmail })
          .then(() => {
          })
          .catch(error => {
            this.$refs.refFormObserver.setErrors(error.response.data.errors)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Invite Employee Failed',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
  setup() {
    const employeeEmail = ref('ernst@besportyapp.com')
    const loading = ref(false)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      employeeEmail,
      loading,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      required,
    }
  },
}
</script>

<style scoped>

</style>
