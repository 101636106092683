<template>
  <div>

    <sport-category-list-add-new
      :is-add-new-sport-category-sidebar-active.sync="isAddNewSportCategorySidebarActive"
    />

    <sport-categories-page-list />

  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import SportCategoryListAddNew from './components/SportCategoryListAddNew.vue'
import SportCategoriesPageList from './components/SportCategoryPageList.vue'

export default {
  name: 'SportCategoriesPage',
  components: {
    SportCategoryListAddNew,
    SportCategoriesPageList,
  },
  setup() {
    const isAddNewSportCategorySidebarActive = ref(false)
    return {
      // Sidebar
      isAddNewSportCategorySidebarActive,
    }
  },
}
</script>
