import { computed, ref, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FETCH_ORGANIZATION_FEEDBACKS } from '@/modules/organization/store/action-types'

export default function useOrganizationFeedbacks() {
  const toast = useToast()

  const refFeedbackListTable = ref(null)

  const tableColumns = [
    { key: 'username', sortable: true },
    { key: 'message', sortable: false },
    { key: 'created_at', sortable: true },
  ]

  const perPage = ref(10)
  const totalFeedbacks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)

  const statusFilter = ref(null)
  const timeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refFeedbackListTable.value ? refFeedbackListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFeedbacks.value,
    }
  })

  const refetchData = () => {
    refFeedbackListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, timeFilter], () => {
    refetchData()
  })

  const fetchFeedbacks = async (ctx, callback) => {
    store
      .dispatch(FETCH_ORGANIZATION_FEEDBACKS, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        time: timeFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data

        callback(data)
        totalFeedbacks.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching organization feedback list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    perPage,
    currentPage,
    totalFeedbacks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFeedbackListTable,

    fetchFeedbacks,
    refetchData,

    statusFilter,
    timeFilter,
  }
}
