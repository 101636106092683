import {
  FAQS_FETCH,
} from '@/modules/faq/store/action-types'
import faqsService from '../services'

export default {
  [FAQS_FETCH]: (context, params) => new Promise((resolve, reject) => {
    faqsService.getFaqs(params)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  }),
}
