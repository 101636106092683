import FeedbackModule from '../FeedbackModule.vue'
import FeedbacksPage from '../views/feedbacks-page/FeedbacksPage.vue'

export default [
  {
    path: '/feedbacks',
    component: FeedbackModule,
    children: [
      {
        path: '/',
        name: 'feedback.list',
        component: FeedbacksPage,
      },
    ],
  },
]
