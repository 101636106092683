import AuthModule from '../AuthModule.vue'

import LoginPage from '../views/LoginPage.vue'
// import store from '../../../store'

// const ifNotAuthenticated = (to, from, next) => {
//   if (!store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next()
// }

export default [
  {
    path: '/',
    redirect: { name: 'login' },
    component: AuthModule,
    children: [
      {
        path: '/login',
        name: 'login',
        component: LoginPage,
        // beforeEnter: ifNotAuthenticated,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
    ],
  },
]
