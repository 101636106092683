<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Personal Information
      </h4>
    </div>

    <!-- Form: Personal Info Form -->

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        v-if="profileData"
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>

          <!-- bio -->
          <b-col cols="12">
            <b-form-group
              label="About"
              label-for="about"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="about"
                name="About"
                rules="required"
                immediate
              >
                <b-form-textarea
                  id="about"
                  v-model="profileData.about"
                  :state="errors.length > 0 ? false:null"
                  rows="6"
                  placeholder="Your bio data here..."
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <!-- Field: Birth Date -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Birth Date"
              label-for="birth-date"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="birth_date"
                name="Birth date"
                rules="required"
                immediate
              >
                <flat-pickr
                  id="birth-date"
                  v-model="profileData.birth_date"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d', allowInput: true}"
                  placeholder="YYYY-MM-DD"
                  :class="errors.length > 0 ? 'is-invalid' :null"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Phone -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="phone"
                name="Phone"
                rules="required"
                immediate
              >
                <b-form-input
                  id="phone"
                  v-model="profileData.phone"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Phone"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Website -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Website"
              label-for="website"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="website"
                name="Website"
                rules="required"
                immediate
              >
                <b-form-input
                  id="website"
                  v-model="profileData.website"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Website"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Language -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Language"
              label-for="language"
            >
              <v-select
                :value="profileData.locale"
                :options="languageOptions"
                :reduce="val => val.value"
                input-id="language"
                :disabled="true"
                @input="(val) => $emit('update:profileData', val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Gender -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Gender"
              label-for="gender"
              label-class="mb-1"
            >
              <b-form-radio-group
                id="gender"
                v-model="profileData.gender"
                :options="genderOptions"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Contact Options -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Contact Options"
              label-for="contact-options"
              label-class="mb-1"
            >
              <b-form-checkbox-group
                id="contact-options"
                v-model="profileData.contactOptions"
                :options="contactOptionsOptions"
              />
            </b-form-group>

            <b-form-group
              label="Contact Options"
              label-for="contact-options"
              label-class="mb-1"
            >
              <b-form-checkbox-group
                id="contact-options"
              >
                <b-form-checkbox
                  v-model="profileData.contact_mail"
                  name="contact_mail"
                >Email</b-form-checkbox>
                <b-form-checkbox
                  v-model="profileData.contact_msg"
                  name="contact_msg"
                >Message</b-form-checkbox>
                <b-form-checkbox
                  v-model="profileData.contact_phone"
                  name="contact_phone"
                >Phone</b-form-checkbox>
              </b-form-checkbox-group>

            </b-form-group>
          </b-col>
        </b-row>

        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Address
          </h4>
        </div>

        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">

          <!-- Field: Address -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                vid="address"
                name="Address"
                rules="required"
                immediate
              >
                <b-form-input
                  id="address"
                  v-model="profileData.address"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Address"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Postcode -->
          <b-col
            cols="4"
            md="2"
            lg="4"
          >
            <b-form-group
              label="Postcode"
              label-for="postcode"
            >
              <validation-provider
                #default="{ errors }"
                vid="postal_code"
                name="Postal code"
                rules="required"
                immediate
              >
                <b-form-input
                  id="postcode"
                  v-model="profileData.postal_code"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Postal code"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: City -->
          <b-col
            cols="8"
            md="4"
            lg="4"
          >
            <b-form-group
              label="City"
              label-for="city"
            >
              <validation-provider
                #default="{ errors }"
                vid="city"
                name="City"
                rules="required"
                immediate
              >
                <b-form-input
                  id="city"
                  v-model="profileData.city"
                  :state="errors.length > 0 ? false:null"
                  placeholder="City"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Country -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Country"
              label-for="country"
            >
              <validation-provider
                #default="{ errors }"
                vid="country"
                name="Country"
                rules="required"
                immediate
              >
                <b-form-input
                  id="country"
                  v-model="profileData.country"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Country"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="onSubmit"
            >
              <b-spinner
                v-if="loading"
                small
              />
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import { PROFILE_UPDATE } from '@/modules/profile/store/action-types'
import store from '@/store'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BSpinner,
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return
        this.loading = true
        store.dispatch(PROFILE_UPDATE, this.profileData)
          .catch(error => {
            this.$refs.refFormObserver.setErrors(error.response.data.errors)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating profile',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
  setup() {
    // const toast = useToast()
    const profile = computed(() => store.getters.getProfile)

    const profileData = {
      about: profile.value.about,
      birth_date: profile.value.birth_date,
      phone: profile.value.phone,
      website: profile.value.website,
      locale: profile.value.locale,
      gender: profile.value.gender,
      contact_mail: profile.value.contact_mail,
      contact_msg: profile.value.contact_msg,
      contact_phone: profile.value.contact_phone,
      address: profile.value.address,
      postal_code: profile.value.postal_code,
      city: profile.value.city,
      country: profile.value.country,
    }

    const loading = ref(false)

    const languageOptions = [
      { label: 'English', value: 'en' },
      { label: 'Spanish', value: 'es' },
      { label: 'German', value: 'de' },
    ]
    // [
    //   'English',
    //   'Spanish',
    //   'German',
    // ]

    const genderOptions = [
      { text: 'Male', value: 'm' },
      { text: 'Female', value: 'f' },
    ]

    const contactOptionsOptions = [
      { text: 'Mail', value: profileData.contact_mail },
      { text: 'Message', value: profileData.contact_msg },
      { text: 'Phone', value: profileData.contact_phone },
    ] // ['Email', 'Message', 'Phone']

    const onReset = event => {
      event.preventDefault()
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    return {
      loading,
      onReset,
      // onSubmit,
      profileData,
      languageOptions,
      genderOptions,
      contactOptionsOptions,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
